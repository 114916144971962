import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import { MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { useAuth } from '../../../utils/auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    setToken(null);
    navigate("/");
  }

  const handleChangePassword = () => {
    navigate("/change-password");
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar sx={{ bgcolor: "#A4BEF3" }} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleChangePassword} sx={{ m: 1 }}>
          {t('password.change.title')}
        </MenuItem>
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {t('common.logout')}
        </MenuItem>
      </Popover>
    </>
  );
}
