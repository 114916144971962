import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import LoginPage from './pages/LoginPage';
import ProtectedRoute from './utils/auth/ProtectedRoute';
import FilesPage from './pages/FilesPage';
import UsersPage from './pages/UsersPage';
import GroupsPage from './pages/GroupsPage';
import SetupOtpPage from './pages/SetupOtpPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import AgentsPage from './pages/AgentsPage';
import MetricsPage from './pages/MetricsPage';
import AgentDetailPage from './pages/AgentDetailsPage';
import useUpdateAxiosBaseURL from './hooks/useUpdateAxiosBaseUrl';


export default function Router() {
  useUpdateAxiosBaseURL(); // This will set the Axios base URL whenever the config is loaded or updated

  const routes = useRoutes([

    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/files" />, index: true },
        { path: 'files', element: <ProtectedRoute><FilesPage /></ProtectedRoute> },
        { path: 'users', element: <ProtectedRoute><UsersPage /></ProtectedRoute> },
        { path: 'groups', element: <ProtectedRoute><GroupsPage /></ProtectedRoute> },
        { path: 'agents', element: <ProtectedRoute><AgentsPage /></ProtectedRoute> },
        { path: 'agents/:id', element: <ProtectedRoute><AgentDetailPage /></ProtectedRoute> },
        { path: 'metrics', element: <ProtectedRoute><MetricsPage /></ProtectedRoute> },

      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '*', element: <Navigate to="/dashboard/files" /> },
      ],
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/setup-otp',
      element: <SetupOtpPage />
    },
    {
      path: '/change-password',
      element: <ProtectedRoute><ChangePasswordPage /></ProtectedRoute>
    }
  ]);



  return routes;
}
