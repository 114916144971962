import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, CardActions, CardContent, Container, Grid, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from '../utils/SnackbarProvider';
import { useTranslation } from 'react-i18next';
import useIsAdmin from '../hooks/useIsAdmin';
import getErrorMessage from '../utils/errorHandler/GetErrorMessage';
import AgentCard from '../sections/agents/AgentCard';
import AddAgentButton from '../sections/agents/AddAgentButton';
import AgentLogsModal from '../sections/agents/AgentsLogsModal';
import { useNavigate } from 'react-router-dom';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteConfirmationDialog from '../sections/agents/DeleteConfirmationDialog';
import API from '../utils/API';
import { HttpStatusCode } from 'axios';



export default function AgentsPage() {
  const [agents, setAgents] = useState({ loading: true, data: [] });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const [logsModalOpen, setLogsModalOpen] = useState(false);
  const [currentAgentIdentifier, setCurrentAgentIdentifier] = useState(null);
  const navigate = useNavigate();


  const { enqueueSnackbar } = useSnackbar();
  const isAdmin = useIsAdmin();
  const { t } = useTranslation();

  const fetchAgents = async () => {
    setAgents({ loading: true, data: [] }); // Show loading state
    try {
      const response = await API.get("/agent/listAgents");
      if (response.status === HttpStatusCode.Ok) {
        setAgents({ loading: false, data: response.data });
      }
    } catch (error) {
      setAgents({ loading: false, data: [] });
      enqueueSnackbar(getErrorMessage(error, t), { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  const handleEditAgent = (agent) => {
    navigate('/dashboard/agents/' + agent.Id);
  };

  const handleDownloadAgent = async (agent) => {
    try {
      const response = await API.get('/agent/downloadAgentConfiguration', { Id: agent.Id });

      // Ensure the API response is a JSON string
      const jsonString = JSON.stringify(response.data);

      // Create a Blob from the JSON string
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);

      // Create a link and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `config.prm`); // Set the file name for the download
      document.body.appendChild(link); // Required for Firefox
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      link.remove();

    }
    catch (error) {
      enqueueSnackbar(getErrorMessage(error, t), { variant: 'error' })
    }

  };

  const handleDeleteAgent = (agent) => {
    setAgentToDelete(agent);
    setShowDeleteConfirmation(true);
  };

  const handleOpenModal = () => {
    navigate('/dashboard/agents/0');
  };

  const confirmDeleteAgent = async () => {
    const payload = {
      id: agentToDelete.Id
    }
    try {
      await API.post('/agent/deleteAgent', payload);
      enqueueSnackbar(t('agents.remove.success'));
      fetchAgents(); // Refresh the agent list after successful deletion
    }
    catch (error) {
      enqueueSnackbar(getErrorMessage(error, t), { variant: 'error' })
    }
    setShowDeleteConfirmation(false);
    setAgentToDelete(null);
  };

  const handleOpenLogsModal = async (agent) => {
    setCurrentAgentIdentifier(agent.AgentIdentifier);
    setLogsModalOpen(true);
  };

  const handleCloseLogsModal = () => {
    setLogsModalOpen(false);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/PermastoreAgent.zip';
    link.download = 'PermastoreAgent.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <>
      <Helmet>
        <title>{t('agents.title')}</title>
      </Helmet>
      <Container maxWidth='xl'>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            {t('agents.title')}
          </Typography>
          <Box display='flex'>
            <AddAgentButton disabled={!isAdmin} onClick={handleOpenModal} />
            <Button
              variant="contained"
              startIcon={<CloudDownloadIcon />}
              color='secondary'
              sx={{ ml: 2 }}
              onClick={handleDownload}
            >
              {t('agents.downloadLatest')}
            </Button>
          </Box>
        </Box>
        {!agents.loading && <AgentLogsModal open={logsModalOpen} onClose={handleCloseLogsModal} agentId={currentAgentIdentifier} />}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {agents.loading ? (
            Array.from(new Array(3)).map((_, index) => (
              <Grid item xs={12} sm={6} lg={4} key={index}>
                <Skeleton variant="rectangular" width={300} height={118} sx={{ borderRadius: 2, mb: 2 }} />
                <Skeleton variant="text" width={280} sx={{ mb: 1 }} />
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={220} />
              </Grid>
            ))
          ) : agents.data.length === 0 ? (
            <Grid item xs={12}>
              <Card sx={{ minWidth: 275, mt: 2 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {t('agents.notfound')}
                  </Typography>
                  <Typography variant="body2">
                    {t('agents.notfound_hint')}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={handleOpenModal}>{t('agents.add')}</Button>
                </CardActions>
              </Card>
            </Grid>
          ) : (
            agents.data.map((agent) => (
              <Grid item xs={12} sm={6} md={6} lg={4} key={agent.Id}>
                <AgentCard
                  agent={agent}
                  onDownload={() => handleDownloadAgent(agent)}
                  onEdit={() => handleEditAgent(agent)}
                  onDelete={() => handleDeleteAgent(agent)}
                  onShowLogs={() => handleOpenLogsModal(agent)}
                />
              </Grid>
            ))
          )}
        </Grid>

        <DeleteConfirmationDialog
          open={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={confirmDeleteAgent}
          agentName={agentToDelete?.Name}
        />
      </Container>
    </>
  );

}
