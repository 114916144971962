import { useEffect } from 'react';
import axios from 'axios';
import { useConfig } from '../utils/ConfigContext';

const useUpdateAxiosBaseURL = () => {
    const { config } = useConfig();

    useEffect(() => {
        // Check if the config and the specific property you need are not undefined
        if (config && config.apiBaseUrl) {
            axios.defaults.baseURL = config.apiBaseUrl;
            axios.defaults.headers.common['tenancy'] = config.tenancy;
        }
    }, [config]);
};

export default useUpdateAxiosBaseURL;
