import React from 'react';
import { Grid, Typography, Card, CardContent, Box, Tooltip } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import StorageIcon from '@mui/icons-material/Storage';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useTranslation } from 'react-i18next';
import formatFileSize from '../../utils/formaFileSize';
import { formatNumberWithSpaces } from '../../utils/utility/formatters';

const StatsCard = ({ icon, title, count, formatFunction, iconColor, cardColor, cornerIcon }) => (
  <Card sx={{ bgcolor: cardColor, position: 'relative' }}>
    <CardContent>
      <Box display="flex" alignItems="center" gap={2}>
        {React.cloneElement(icon, { color: iconColor })}
        <Typography variant="h6">{title}</Typography>
        {cornerIcon}
      </Box>
      <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '1.5rem', paddingTop: '0.5rem' }}>
        {formatFunction(count)}
      </Typography>
    </CardContent>
  </Card>
);

const CurrentStats = ({
  totalUploadFileCount,
  totalUploadBytesCount,
  totalDownloadFileCount,
  totalDownloadBytesCount,
}) => {
  const { t } = useTranslation();

  const stats = [
    {
      icon: <FolderIcon />,
      title: t('metrics.totalFiles'),
      count: totalUploadFileCount,
      formatFunction: formatNumberWithSpaces,
      iconColor: 'primary',
      cardColor: 'uploadCard',
      cornerIcon: <Tooltip title={t('metrics.upload')} ><CloudUploadIcon color='success' sx={{ flex: 1 }} /> </Tooltip>
    },
    {
      icon: <StorageIcon />,
      title: t('metrics.totalSize'),
      count: totalUploadBytesCount,
      formatFunction: formatFileSize,
      iconColor: 'secondary',
      cardColor: 'uploadCard',
      cornerIcon: <Tooltip title={t('metrics.upload')} ><CloudUploadIcon color='success' sx={{ flex: 1 }} /> </Tooltip>
    },
    {
      icon: <FolderIcon />,
      title: t('metrics.totalFiles'),
      count: totalDownloadFileCount,
      formatFunction: formatNumberWithSpaces,
      iconColor: 'primary',
      cardColor: 'downloadCard',
      cornerIcon: <Tooltip title={t('metrics.download')} ><CloudDownloadIcon color='error' sx={{ flex: 1 }} /> </Tooltip>
    },
    {
      icon: <StorageIcon />,
      title: t('metrics.totalSize'),
      count: totalDownloadBytesCount,
      formatFunction: formatFileSize,
      iconColor: 'secondary',
      cardColor: 'downloadCard',
      cornerIcon: <Tooltip title={t('metrics.download')} ><CloudDownloadIcon color='error' sx={{ flex: 1 }} /> </Tooltip>
    }
  ];

  return (
    <Box>
      <Grid container spacing={2} mb={4}>
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StatsCard {...stat} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CurrentStats;
