import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

function AddAgentButton({ onClick, loading, disabled }) {
    const { t } = useTranslation();

    return (
        <LoadingButton
            disabled={disabled}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onClick}
            loading={loading}
        >
            {t('agents.add')}
        </LoadingButton>
    );
}

export default AddAgentButton;
