const getErrorMessage = (errorObj, t) => {    
    let { Code, Message } = errorObj;

    if (!Code && !Message) {
        Code = 'E9999';
    }

    return Code ? t(`error.${Code}`, { message: Message }) : Message;
};

export default getErrorMessage;
