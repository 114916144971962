import { createContext, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [token, setToken_] = useState(localStorage.getItem("token"));

    const [user, setUser] = useState(JSON.parse(localStorage.getItem("permastore_user")));


    const setToken = (newToken) => {
        setToken_(newToken);
    };

    useEffect(() => {
        if (token) {
            axios.defaults.headers.common["AccessToken"] = token;
            localStorage.setItem("token", token);
            localStorage.setItem("permastore_user", JSON.stringify(user));

        } else {
            delete axios.defaults.headers.common["AccessToken"];
            localStorage.removeItem("token");
            localStorage.removeItem("permastore_user");

        }
    }, [token, user]);

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            token,
            setToken,
            user,
            setUser
        }),
        [token, user]
    );

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;