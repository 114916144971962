const generateMonthsInRange = (start, end) => {
    let startDate = new Date(`${start}-01`); // Ensure proper date format
    let endDate = new Date(`${end}-01`);
    const allMonths = [];
    while (startDate <= endDate) {
        allMonths.push(`${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}`);
        startDate.setMonth(startDate.getMonth() + 1);
    }
    return allMonths;
};
export default generateMonthsInRange;