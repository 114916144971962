import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, IconButton, Tooltip, TablePagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TagComponent from '../../utils/tags/TagComponent';
import useIsAdmin from '../../hooks/useIsAdmin';
import { useTranslation } from 'react-i18next';

const styles = {
    tableContainer: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        borderRadius: '20px',
        overflow: 'hidden',
    },
    table: {
        minWidth: 650,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
    headerCell: {
        fontWeight: 'bold',
        backgroundColor: 'linear-gradient(180deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
        color: 'white',
        borderBottom: '1px solid #ddd',
        '&:hover': {
            color: 'lightgray',
        },
    },
    bodyCell: {
        fontSize: '0.95rem',
        color: 'dimgrey',
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(245, 245, 245, 0.65)',
        },
        '&:hover': {
            backgroundColor: 'rgba(235, 235, 235, 0.8)',
        },
    },
};

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { order, orderBy, onRequestSort } = props;
    const headCells = [
        { id: 'GroupName', label: 'group.name' },
        { id: 'Tags', label: 'group.tags' },
        { id: 'CreatedAt', label: 'group.createdAt' },
        { id: 'Actions', label: '' },
    ];

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function GroupTable({ groups, onEdit, onDelete }) {

    const MAX_TAGS_DISPLAY = 4; // Max number of tags to display directly

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('GroupName');
    const [page, setPage] = useState(0); 
    const [rowsPerPage, setRowsPerPage] = useState(10); 

    const isAdmin = useIsAdmin();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page to 0 when rows per page changes
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const sortArray = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    };

    const sortedGroups = sortArray(groups, getComparator(order, orderBy));

    const paginatedGroups = sortedGroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <TableContainer component={Paper} sx={styles.tableContainer}>
            <Table sx={styles.table} aria-label="customized table">
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {paginatedGroups.map((group) => (
                        <TableRow key={group.GroupId} sx={styles.tableRow}>
                            <TableCell sx={styles.bodyCell}>{group.GroupName}</TableCell>
                            <TableCell sx={styles.bodyCell}>
                                {group.Tags && Object.entries(group.Tags).slice(0, MAX_TAGS_DISPLAY).map(([key, value]) => (
                                    <TagComponent key={key} tagKey={key} tagValue={value} />
                                ))}
                                {Object.keys(group.Tags).length > MAX_TAGS_DISPLAY && (
                                    <Tooltip title={Object.entries(group.Tags).slice(MAX_TAGS_DISPLAY).map(([key, value]) => `${key}: ${value}`).join(', ')}>
                                        <span>+{Object.keys(group.Tags).length - MAX_TAGS_DISPLAY} more</span>
                                    </Tooltip>
                                )}
                            </TableCell>
                            <TableCell sx={styles.bodyCell}>{formatDate(group.CreatedAt)}</TableCell>
                            <TableCell>
                                {isAdmin && (<>
                                    <IconButton onClick={() => onEdit(group.GroupId)}>
                                        <EditIcon color="primary" />
                                    </IconButton>
                                    <IconButton onClick={() => onDelete(group.GroupId)}>
                                        <DeleteIcon color="secondary" />
                                    </IconButton>
                                </>)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={groups.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
}

export default GroupTable;
