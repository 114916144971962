import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            html, body, #root {
              height: 100%;
              margin: 0;
              padding: 0;
            }
            a {
              textDecoration: 'none'; // Removes the underline from all <a> tags, including Material-UI Link components
            }
          `,
    }
  },
  palette: {
    primary: { main: "#6e72d8" },
    secondary: { main: "#A4BEF3" },
    success: { main: "#307351" },
    error: {main: "#AF395F"}
  },
});

export default theme;