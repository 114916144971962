import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Paper, CircularProgress, Autocomplete, InputLabel, Select, MenuItem, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import API from '../../utils/API';
import { useSnackbar } from '../../utils/SnackbarProvider';
import { useTranslation } from 'react-i18next';
import theme from '../../utils/theme';
import getErrorMessage from '../../utils/errorHandler/GetErrorMessage';
import { useAuth } from '../../utils/auth/AuthProvider';
import { isValidPassword } from '../../utils/auth/passwordValidation';
import { Tooltip, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const keyValueStyle = {
    wordBreak: 'break-all', // Ensures long text wraps
};

function CreateUserModal({ open, handleClose: closeHandler, user, onRefresh, groups, loading, existingUserEmails }) {
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [surname, setSurname] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [resetPassword, setResetPassword] = useState(false); // State to track password reset option
    const [resetOtp, setResetOtp] = useState(false); // State for Reset OTP option
    const [, setUserGroupsIds] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [technicalResponse, setTechnicalResponse] = useState(null); // For storing API response for technical user
    const [emailError, setEmailError] = useState('');
    const [groupsError, setGroupsError] = useState(''); // managing groups error

    const { t } = useTranslation();
    const { user: currentUser } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (user && open) {
            setEmail(user.Email || '');
            setFirstname(user.Firstname || '');
            setSurname(user.Surname || '');
            setRole(user.Role || '');
            setUserGroupsIds(user.GroupsIds || []);
            setUserId(user.Id || null);
            setSelectedGroups(groups.filter(g => user.GroupsIds.includes(g.GroupId)));
        }
    }, [user, open, groups]);

    const resetForm = () => {
        setEmail('');
        setFirstname('');
        setSurname('');
        setRole('');
        setPassword('');
        setSelectedGroups([]);
        setUserId(null);
        setResetPassword(false);
        setResetOtp(false);
        setTechnicalResponse(null); // Reset technical user response as well
    };

    const handleClose = () => {
        resetForm();
        closeHandler();
        onRefresh();
    };

    const validateEmailFormat = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const isEmailAlreadyInUse = () => {
        return !existingUserEmails.includes(email.trim())
    }

    const isFormValid = () => {
        const hasGroups = selectedGroups.length > 0;
        const validaData = email && firstname && surname && role && hasGroups && emailError === '';
    
        // New user scenario: password must always be validated
        if (!userId) {
            return validaData && isValidPassword(password);
        }
    
        // Editing existing user scenario: validate password only if resetPassword is checked
        return validaData && (!resetPassword || (resetPassword && isValidPassword(password)));
    };
    

    const handlePasswordResetChange = (event) => {
        setResetPassword(event.target.checked);
    };

    const handleSubmit = async () => {

        if (selectedGroups.length === 0) {
            setGroupsError(t('users.selectAtLeastOneGroup')); // Set error message
            return; // Prevent form submission
        } else {
            setGroupsError(''); // Clear error if validation passes
        }

        setIsLoading(true); // Start loading

        let payload = {
            Email: email,
            Firstname: firstname,
            Surname: surname,
            Role: role,
            UserGroupsIds: selectedGroups.map(group => group.GroupId),
            ...(userId && { ResetOtp: resetOtp }),
        };

        // Include password in payload only if it's a new user or password reset is checked
        if (!userId || resetPassword) {
            payload.Password = password;
        }

        try {
            let endpoint = userId ? "/user/editUser" : "/user/addUser";
            payload.Id = userId || null;

            // Check if role is Technical and adapt endpoint accordingly
            if (role === "Technical" && !userId) {
                endpoint = "/user/addTechnicalUser";
            }

            const response = await API.post(endpoint, payload);

            if (role === "Technical" && !userId) {
                // Instead of closing, set the response to state to display it
                setTechnicalResponse(response.data);
            } else {
                onRefresh();
                handleClose();
                enqueueSnackbar(userId ? t('users.update.success') : t('users.create.success'));
            }
        } catch (error) {
            enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'warning' });
        } finally {
            setIsLoading(false); // Stop loading regardless of the outcome
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            // Notify user of successful copy
            enqueueSnackbar(t('common.copied'), { variant: 'success' });
        });
    };

    useEffect(() => {
        if (email.length > 0 && !userId) {
            if (!validateEmailFormat(email)) {
                setEmailError(t('users.invalidEmail'));
            } else if (!isEmailAlreadyInUse()) {
                setEmailError(t('users.emailInUse'));
            } else {
                setEmailError(''); // Clear error if email is valid and not in use
            }
        }
        // eslint-disable-next-line
    }, [email, existingUserEmails, t]);


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="create-user-modal"
            aria-describedby="modal-modal-description"
        >
            <Paper sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {userId ? t('users.edit') : t('users.add')}
                </Typography>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <TextField
                            fullWidth
                            label={t('users.email')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            margin="normal"
                            required
                            disabled={!!userId}
                            error={emailError.length > 0}
                            helperText={emailError}
                        // error={!validateEmail(email) && email.length > 0}
                        // helperText={!validateEmail(email) && email.length > 0 ? t('users.invalidEmail') : ''}
                        />
                        <TextField
                            fullWidth
                            label={t('users.name')}
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label={t('users.surname')}
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            margin="normal"
                            required
                        />
                        {(!userId || resetPassword) && role !== "Technical" && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                                <TextField
                                    fullWidth
                                    required={!userId || resetPassword}
                                    label={t('users.password')}
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    margin="normal"
                                    sx={{ mr: 1, flex: 1 }}
                                    error={password.length > 0 && !isValidPassword(password)}
                                />
                                <Tooltip
                                    title={
                                        <ul style={{ paddingLeft: 15 }}>
                                            <li>{t('password.policy.length')}</li>
                                            <li>{t('password.policy.uppercase')}</li>
                                            <li>{t('password.policy.lowercase')}</li>
                                            <li>{t('password.policy.digit')}</li>
                                            <li>{t('password.policy.special')}</li>
                                        </ul>
                                    }
                                    placement="right"
                                    arrow
                                >
                                    <IconButton>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        <Autocomplete
                            multiple
                            id="groups-select"
                            options={groups}
                            getOptionLabel={(option) => option.GroupName}
                            value={selectedGroups}
                            onChange={(event, newValue) => {
                                setSelectedGroups(newValue);
                                setUserGroupsIds(newValue.map(group => group.GroupId));
                            }}
                            error={groupsError.length > 0}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={t('users.groups')}
                                    placeholder={t('users.selectGroups')}
                                />
                            )}
                            sx={{ mt: 2, mb: 2 }}
                        />
                        {groupsError && (
                            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                {groupsError} 
                            </Typography>
                        )}
                        <FormControl required fullWidth sx={{ mt: 2, mb: 2 }} disabled={currentUser.UserId === userId}>
                            <InputLabel id="role-select-label">{t('users.role')}</InputLabel>
                            <Select
                                labelId="role-select-label"
                                id="role-select"
                                value={role}
                                label={t('users.role')}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <MenuItem value={"Admin"}>Admin</MenuItem>
                                <MenuItem value={"User"}>User</MenuItem>
                                <MenuItem value={"Technical"}>Technical User</MenuItem>
                            </Select>
                        </FormControl>
                        <Box>
                            {userId && (<>
                                <FormControlLabel
                                    control={<Checkbox checked={resetPassword} onChange={handlePasswordResetChange} />}
                                    label={t('users.resetPassword')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={resetOtp}
                                            onChange={(e) => setResetOtp(e.target.checked)}
                                        />
                                    }
                                    label={t('users.resetOtp')}
                                />
                            </>
                            )}
                        </Box>

                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={isLoading || !isFormValid()}
                            sx={{ mt: 2, mr: 1 }}
                        >
                            {isLoading ? <CircularProgress size={24} /> : userId ? t('users.update') : t('users.add')}
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{ mt: 2 }}
                        >
                            {t('common.cancel')}
                        </Button>
                    </>
                )}
                {!loading && technicalResponse && (
                    <Box marginTop={2}>
                        <Typography variant="body1" component="div" color={theme.palette.primary.main}>
                            {t('users.apiKey')}
                        </Typography>
                        <Typography variant="body2" component="div" sx={keyValueStyle}>
                            {technicalResponse.ApiKey}
                            <Button onClick={() => copyToClipboard(technicalResponse.ApiKey)} sx={{ ml: 1 }}>
                                {t('common.copy')}
                            </Button>
                        </Typography>
                        <Typography variant="body1" component="div" sx={{ mt: 2 }} color={theme.palette.primary.main}>
                            {t('users.hmacKey')}
                        </Typography>
                        <Typography variant="body2" component="div" sx={keyValueStyle}>
                            {technicalResponse.HmacKey}
                            <Button onClick={() => copyToClipboard(technicalResponse.HmacKey)} sx={{ ml: 1 }}>
                                {t('common.copy')}
                            </Button>
                        </Typography>
                        <Typography variant="body1" component="div" sx={{ mt: 2 }} color={theme.palette.primary.main}>
                            {t('users.endpoint')}
                        </Typography>
                        <Typography variant="body2" component="div" sx={keyValueStyle}>
                            {technicalResponse.Endpoint}
                            <Button onClick={() => copyToClipboard(technicalResponse.Endpoint)} sx={{ ml: 1 }}>
                                {t('common.copy')}
                            </Button>
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            sx={{ mt: 3 }}
                        >
                            {t('common.close')}
                        </Button>
                    </Box>
                )}
            </Paper>
        </Modal>
    );
}

export default CreateUserModal;
