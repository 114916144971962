import React, { useState } from 'react';
import { Box, Grid, Card, CardMedia, Typography, Modal, Fade, Paper, Tooltip, IconButton, Divider } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import formatFileSize from '../../utils/formaFileSize';
import TagComponent from '../../utils/tags/TagComponent';
import { useConfig } from '../../utils/ConfigContext';

export default function FileTablePreview({ files }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const { config } = useConfig();

    const handleClose = () => setSelectedFile(null);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {files.map((file, index) => (
                    <Grid item xs={3} key={index}>
                        <FileCard tenancy={config.tenancy} file={file} onClick={() => setSelectedFile(file)} />
                    </Grid>
                ))}
            </Grid>
            {selectedFile && <FileModal tenancy={config.tenancy} file={selectedFile} onClose={handleClose} />}
        </Box>
    );
}

function handleImageError(e) {
    e.target.onerror = null;
    e.target.src = '/assets/placeholder-image.webp';
}

function generateImageUrl(tenancy, fileName, size) {
    const baseUrl = `https://permastore-demo-images-previews.s3.eu-west-1.amazonaws.com/${tenancy}/`;
    return `${baseUrl}${fileName}_${size}_preview.jpg`;
}


function FileModal({ tenancy, file, onClose }) {
    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A';

        const date = new Date(timestamp);
        const fullDate = date.toLocaleString();
        const displayDate = date.toLocaleDateString();

        return (
            <Tooltip title={fullDate}>
                <span>{displayDate}</span>
            </Tooltip>
        );
    };

    const Tags = ({ file }) => {
        const tags = Object.entries(file.Tags);
        return (
            <Grid>
                {tags.map(([key, value]) => (
                    <Grid item key={key}>
                        <TagComponent key={key} tagKey={key} tagValue={value} />
                    </Grid>
                ))}
            </Grid>
        );
    };

    function renderStorageClassBadge(storageClass) {
        const style = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '5px',
            backgroundColor: storageClass === 'W' ? '#ffcc80' : '#bbdefb',
            color: storageClass === 'W' ? '#ef6c00' : '#0d47a1',
            borderRadius: '20px',
            padding: '5px 10px',
            maxWidth: '2.5rem',
        };

        return (
            <Tooltip title={storageClass === 'W' ? 'Warm' : 'Cold'}>
                <div style={style}>
                    {storageClass === 'W' ? <WhatshotIcon /> : <AcUnitIcon />}
                </div>
            </Tooltip>
        );
    }

    const { t } = useTranslation();

    return (
        <Modal open={!!file} onClose={onClose} closeAfterTransition>
            <Fade in={!!file}>
                <Paper
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" component="h2" mb={3} ml={3}>
                        {file.Name}
                    </Typography>
                    <Box display='flex' flexDirection='row' justifyContent='space-between'>
                        <Box p={3}>
                            <img alt={file.Name} src={generateImageUrl(tenancy, file.S3Path, '640x480')} style={{
                                borderRadius: 5,
                                objectFit: 'contain',
                                boxShadow: '5px 5px 15px 5px #0000006b'
                            }}></img>
                        </Box>
                        <Box p={3}>
                            <Grid container item spacing={3} direction="column" sx={{ height: '100%', borderRadius: 5 }}>
                                <Grid item>
                                    <Box mb={2}>
                                        <Typography variant="body2" color="text.secondary">
                                            {t('files.path')}
                                        </Typography>
                                        <Typography>{file.OriginalFilePath}</Typography>
                                        <Divider />
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="body2" color="text.secondary">
                                            {t('files.size')}
                                        </Typography>
                                        <Typography>{formatFileSize(file.FileSize)}</Typography>
                                        <Divider />
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="body2" color="text.secondary">
                                            {t('files.createdAt')}
                                        </Typography>
                                        <Typography>{formatDate(file.CreatedAt)}</Typography>
                                        <Divider />
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="body2" color="text.secondary">
                                            {t('files.uploadedAt')}
                                        </Typography>
                                        <Typography>{formatDate(file.UploadedAt)}</Typography>
                                        <Divider />
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="body2" color="text.secondary">
                                            {t('files.lastDownloadAt')}
                                        </Typography>
                                        <Typography>{formatDate(file.LastDownloadAt)}</Typography>
                                        <Divider />
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="body2" color="text.secondary">
                                            {t('files.storageClass')}
                                        </Typography>
                                        <Typography>{renderStorageClassBadge(file.StorageClass)}</Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {t('files.tags')}
                                        </Typography>
                                        <Tags file={file} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Paper>
            </Fade>
        </Modal>
    );
}

function FileCard({ tenancy, file, onClick }) {
    return (
        <Card
            onClick={onClick}
            sx={{
                cursor: 'pointer',
                transition: 'transform 0.2s',
                '&:hover': {
                    transform: 'scale(1.05)',
                },
                height: '100%'
            }}
        >
            <CardMedia
                component="img"
                height="300"
                image={generateImageUrl(tenancy, file.S3Path, '300x300')}
                alt={file.Name}
                onError={handleImageError}
                sx={{
                    objectFit: 'contain',
                    padding: '1rem'
                }}
            />
            <Typography variant="body2" color="textSecondary" component="p" sx={{ padding: 2 }}>
                {file.Name}
            </Typography>
        </Card>
    );
}
