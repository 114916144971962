import axios from "axios";

// Set the default base URL from the environment variable
// axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
// moved to hook useUpdateAxiosBaseurl


const getConfig = (params, baseURL, responseType = 'json') => {
    const AUTH_TOKEN = localStorage.getItem("token");

    let config = {
        params: params,
        headers: {
            'AccessToken': `${AUTH_TOKEN}`
        },
        responseType: responseType 
    };

    if (baseURL) {
        config.baseURL = baseURL;
    }

    return config;
}


const get = (url, params, baseURL, responseType) => {
    return axios.get(url, getConfig(params, baseURL, responseType));
}

const post = (url, payload, baseURL) => {
    return axios.post(url, payload, getConfig(undefined, baseURL));
}

const API = { get, post }

export default API;
