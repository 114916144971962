import { Helmet } from 'react-helmet-async';
import { Box, Container, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import API from '../utils/API';
import { HttpStatusCode } from 'axios';
import ConfirmationDialog from '../utils/ConfirmationDialog';
import { useSnackbar } from '../utils/SnackbarProvider';
import { useTranslation } from 'react-i18next';
import AddUserButton from '../sections/users/AddUserButton';
import UserTable from '../sections/users/UserTable';
import CreateUserModal from '../sections/users/CreateUserModal';
import useIsAdmin from '../hooks/useIsAdmin';
import getErrorMessage from '../utils/errorHandler/GetErrorMessage';

export default function UsersPage() {
  const { t } = useTranslation();
  const [users, setUsers] = useState({ loading: true, data: [] });
  const [groups, setGroups] = useState({ loading: true, data: [] });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableUser, setEditableUser] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const isAdmin = useIsAdmin();

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setEditableUser(null); // Ensure modal opens in "create" mode by default
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditableUser(null); // Reset editable group when closing modal
  };

  const handleEdit = (userId) => {
    const userToEdit = users.data.find(user => user.Id === userId);
    if (userToEdit) {
      setEditableUser(userToEdit);
      setIsModalOpen(true);
    }
  };

  const handleDelete = (userId) => {
    setUserIdToDelete(userId);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setUserIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    setIsConfirmLoading(true);
    const payload = {
      Id: userIdToDelete
    };
    try {

      await API.post("/user/deleteUser", payload);
      enqueueSnackbar(t('users.remove.success'));
    }
    catch (error) {
      enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'warning' })
    }
    finally {
      setIsConfirmLoading(false);
      handleCloseDialog();
      refreshUsers();
    }
  };

  const refreshUsers = async () => {
    const response = await API.get("/user/listUsers");
    if (response.status === HttpStatusCode.Ok) {
      setUsers({
        data: response.data,
        loading: false
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      const listUsersResponse = await API.get("/user/listUsers");
      if (listUsersResponse.status === HttpStatusCode.Ok) {
        setUsers({
          data: listUsersResponse.data,
          loading: false
        })
      }

      const listGroupsResponse = await API.get("/group/listGroups");
      if (listGroupsResponse.status === HttpStatusCode.Ok) {
        setGroups({
          data: listGroupsResponse.data,
          loading: false
        })
      }

    }

    getData();
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('users.title')}</title>
      </Helmet>
      <Container maxWidth='xl'>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            {t('users.title')}
          </Typography>
          <AddUserButton disabled={!isAdmin} onClick={handleOpenModal} />
        </Box>
        {!users.loading && isModalOpen && (<CreateUserModal open={isModalOpen} handleClose={handleCloseModal} user={editableUser} groups={groups.data} loading={groups.loading} onRefresh={refreshUsers} existingUserEmails={users.data.map(u => u.Email)} />)}
        <ConfirmationDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmDelete}
          title={t('common.confirmDeletion')}
          loading={isConfirmLoading}
        >
          {t('dialog.confirm_delete')}
        </ConfirmationDialog>
        {users.loading ? (
          <Box>
            {Array.from(new Array(5)).map((_, index) => (
              <Box key={index} display="flex" mb={1}>
                <Skeleton variant="text" width="40%" height={30} />
                <Skeleton variant="rectangular" width="60%" height={60} style={{ marginLeft: 16 }} />
              </Box>
            ))}
          </Box>
        ) : (
          <UserTable
            users={users.data}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        )}
      </Container>
    </>
  );
}