import React from 'react';
import { Button } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

function AddGroupButton({ onClick, disabled }) {
    return (
        <Button
            disabled={disabled}
            variant="contained"
            startIcon={<GroupAddIcon />}
            onClick={onClick}
        >
            Add Group
        </Button>
    );
}

export default AddGroupButton;
