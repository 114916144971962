const passwordCriteria = [
    { test: (pw) => pw.length >= 12, message: 'password.policy.length' },
    { test: (pw) => /[A-Z]/.test(pw), message: 'password.policy.uppercase' },
    { test: (pw) => /[a-z]/.test(pw), message: 'password.policy.lowercase' },
    { test: (pw) => /\d/.test(pw), message: 'password.policy.digit' },
    { test: (pw) => /[!@#$%^&*]/.test(pw), message: 'password.policy.special' },
];

const isValidPassword = (password) => {
    if (!password)
        return false;
    // Count how many criteria the password meets
    const criteriaMet = passwordCriteria.reduce((count, crit) => count + crit.test(password), 0);

    // Return true if at least 4 out of 5 criteria are met
    return criteriaMet >= 4;
};

module.exports = { passwordCriteria, isValidPassword };
