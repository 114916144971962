import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Tooltip, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import API from '../../utils/API';
import { HttpStatusCode } from 'axios';
import getErrorMessage from '../../utils/errorHandler/GetErrorMessage';
import { useSnackbar } from '../../utils/SnackbarProvider';

function AgentLogsModal({ open, onClose, agentId }) {
  const [logs, setLogs] = useState({ loading: false, data: [] });
  const [sortOrder, setSortOrder] = useState('desc'); // Ascending or Descending
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fetchLogs = async () => {
    setLogs({ loading: true, data: [] });
    try {
      const response = await API.get("/agent/listAgentLogs", { agentIdentifier: agentId });
      if (response.status === HttpStatusCode.Ok) {
        const sortedData = response.data.reverse();
        setLogs({ loading: false, data: sortedData });
      }
    } catch (error) {
      setLogs({ loading: false, data: [] });
      enqueueSnackbar(getErrorMessage(error, t), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (open) {
      fetchLogs();
    }
  }, [open, agentId]);

  useEffect(() => {
    // Sort logs in the current sortOrder when sortOrder changes
    const sortedData = [...logs.data].reverse();
    setLogs(prevLogs => ({ ...prevLogs, data: sortedData }));
  }, [sortOrder]);

  const toggleSortOrder = () => {
    setSortOrder(prevSortOrder => prevSortOrder === 'asc' ? 'desc' : 'asc');
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const fullDate = date.toLocaleString();

    return (
      <Tooltip title={fullDate}>
        <span>{fullDate}</span>
      </Tooltip>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {t('agents.logs.title')}
        <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {logs.loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <TableContainer component={Paper} style={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell onClick={toggleSortOrder} style={{ cursor: 'pointer' }}>
                    {t('agents.logs.timestamp')} {sortOrder === 'asc' ? '↑' : '↓'}
                  </TableCell>
                  <TableCell>{t('agents.logs.severity')}</TableCell>
                  <TableCell>{t('agents.logs.message')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.data.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell>{formatDate(log.timestamp)}</TableCell>
                    <TableCell>{log.severity}</TableCell>
                    <TableCell>{log.message}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default AgentLogsModal;
