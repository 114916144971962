import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import SetupAxiosInterceptors from '../../utils/API/SetupAxiosInterceptors';
import { useConfig } from '../../utils/ConfigContext';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';


// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 8,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 8,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const { config, isLoading } = useConfig();

  if (isLoading) {
    return <CircularProgress />
  }

  if(!isLoading && !config.apiBaseUrl){
    return <Alert severity="error">Missing or invalid configuration</Alert>
  }

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <SetupAxiosInterceptors />
      <Main
      >
        <Outlet />
        <div
          className="iceberg"
          style={{
            zIndex: -1,
            position: "fixed",
            bottom: -150, // Position at the bottom
            right: 0, // Position at the right
            backgroundImage: "url('/assets/images/iceberg-05.svg')",
            backgroundRepeat: 'no-repeat', // Prevent the image from repeating
            width: '400px', // Specify the width of the image (adjust as needed)
            height: '400px', // Specify the height of the image (adjust as needed)
            backgroundSize: 'contain', // Ensure the image fits within the dimensions
          }}
        ></div>
      </Main>
    </StyledRoot>
  );
}
