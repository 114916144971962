import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import formatFileSize from '../../utils/formaFileSize';
import generateMonthsInRange from '../../utils/generateMonthsInRange';
import { formatNumberWithSpaces } from '../../utils/utility/formatters';

const UsageTrendChart = ({ data, fromDate, toDate }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [chartOptions, setChartOptions] = useState({
        colors: [theme.palette.error.main, theme.palette.warning.main, theme.palette.primary.main, theme.palette.secondary.main],
        chart: {
            type: 'line',
            height: 350
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: [2, 2],
        },
        xaxis: {
            categories: [], // Will be dynamically updated
        },
        yaxis: [{
            title: {
                text: t('metrics.newFileCount'),
            },
            labels: {
                formatter: function (val) {
                    return formatNumberWithSpaces(val);
                }
            }
        }, {
            opposite: true,
            title: {
                text: t('metrics.newTotalBytes'),
            },
            labels: {
                formatter: val => formatFileSize(val),
            }
        }],
        tooltip: {
            y: [{
                formatter: val => `${formatNumberWithSpaces(val)} files`,
            }, {
                formatter: val => formatFileSize(val),
            }]
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    });

    const [series, setSeries] = useState([]);

    useEffect(() => {
        const months = generateMonthsInRange(fromDate, toDate);
        let cumulativeData = {
            'W': { fileCounts: [], totalBytes: [] },
            'C': { fileCounts: [], totalBytes: [] }
        };

        let cumulativeTotals = {
            'W': { fileCount: 0, totalBytes: 0 },
            'C': { fileCount: 0, totalBytes: 0 }
        };

        months.forEach(month => {
            const monthData = data.filter(d => `${d.Year}-${String(d.Month).padStart(2, '0')}` === month);

            monthData.forEach(d => {
                cumulativeTotals[d.StorageClass].fileCount += d.UploadFileCount;
                cumulativeTotals[d.StorageClass].totalBytes += d.UploadTotalBytes;
            });

            ['W', 'C'].forEach(storageClass => {
                cumulativeData[storageClass].fileCounts.push(cumulativeTotals[storageClass].fileCount);
                cumulativeData[storageClass].totalBytes.push(cumulativeTotals[storageClass].totalBytes);
            });
        });


        setSeries([
            {
                name: 'File Count (W)',
                type: 'line',
                data: cumulativeData['W'].fileCounts.every(value => value === 0) ? [] : cumulativeData['W'].fileCounts
            },
            {
                name: 'Total Bytes (W)',
                type: 'line',
                data: cumulativeData['W'].totalBytes.every(value => value === 0) ? [] : cumulativeData['W'].totalBytes
            },
            {
                name: 'File Count (C)',
                type: 'line',
                data: cumulativeData['C'].fileCounts.every(value => value === 0) ? [] : cumulativeData['C'].fileCounts
            },
            {
                name: 'Total Bytes (C)',
                type: 'line',
                data: cumulativeData['C'].totalBytes.every(value => value === 0) ? [] : cumulativeData['C'].totalBytes
            }
        ]);


        setChartOptions(prevOptions => ({
            ...prevOptions,
            xaxis: { ...prevOptions.xaxis, categories: months }
        }));
    }, [data, fromDate, toDate, t]); // Update chart data and options when props change

    return (
        <div id="chart">
            <Chart options={chartOptions} series={series} type="line" height={350} />
        </div>
    );
};

export default UsageTrendChart;
