import { createContext, useContext, useState, useEffect } from 'react';

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
    const [config, setConfig] = useState({});
    const [isLoading, setIsLoading] = useState(true); // Add a loading state


    useEffect(() => {
        const loadConfig = async () => {
            try {
                const fetchedConfig = await (await fetch('/config.json')).json();
                setConfig(fetchedConfig);
            } catch (err) {
                // improve this
                console.error("Failed to load configuration", err);

            }finally{
                setIsLoading(false);
            } 
        };

        loadConfig();
    }, []);

    // Pass both config and isLoading in the context value
    return (
        <ConfigContext.Provider value={{ config, isLoading, setConfig, setIsLoading }}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = () => useContext(ConfigContext);
