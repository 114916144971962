import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Tooltip, useMediaQuery } from '@mui/material';
import TagComponent from '../../utils/tags/TagComponent';
import formatFileSize from '../../utils/formaFileSize';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import getErrorMessage from '../../utils/errorHandler/GetErrorMessage';
import { useSnackbar } from '../../utils/SnackbarProvider';
import { useTranslation } from 'react-i18next';
import API from '../../utils/API';
import { useTheme } from '@emotion/react';

const styles = {
    tableContainer: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        borderRadius: '20px',
        // maxHeight: 'calc(60vh)',
    },
    table: {
        minWidth: 650,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
    headerCell: {
        fontWeight: 'bold',
        backgroundColor: 'linear-gradient(180deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
        color: 'white',
        borderBottom: '1px solid #ddd',
        '&:hover': {
            color: 'lightgray',
        },
    },
    bodyCell: {
        fontSize: '0.95rem',
        color: 'dimgrey',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '1rem',
        maxWidth: '9rem'
    },
    bodyCellDowload: {
        padding: '1rem',
        maxWidth: '9rem'
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(245, 245, 245, 0.65)',
        },
        '&:hover': {
            backgroundColor: 'rgba(235, 235, 235, 0.8)',
        },
    },
    noResultRow: {
        '& > td': {
            textAlign: 'center',
            color: 'gray',
            fontStyle: 'italic',
        },
    },
};

function renderStorageClassBadge(storageClass) {
    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
        backgroundColor: storageClass === 'W' ? '#ffcc80' : '#bbdefb',
        color: storageClass === 'W' ? '#ef6c00' : '#0d47a1',
        borderRadius: '20px',
        padding: '5px 10px',
        maxWidth: "2.5rem"
    };

    return (
        <Tooltip title={storageClass === 'W' ? 'Warm' : 'Cold'}>
            <div style={style}>
                {storageClass === 'W' ? <WhatshotIcon /> : <AcUnitIcon />}
            </div>
        </Tooltip>
    );
}


function EnhancedTableHead(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xl'));
    const { t } = useTranslation();

    const { order, orderBy, onRequestSort } = props;
    let headCells = [
        { id: 'Actions', label: '' },
        { id: 'Name', label: 'files.name' },
        { id: 'OriginalFilePath', label: 'files.path' },
        { id: 'FileSize', label: 'files.size' },
        { id: 'Tags', label: 'files.tags' },
        { id: 'CreatedAt', label: 'files.createdAt' },
        { id: 'UploadedAt', label: 'files.uploadedAt' },
        { id: 'LastDownloadAt', label: 'files.lastDownloadAt' },
        { id: 'StorageClass', label: 'files.storageClass' }
    ];

    if (matches) {
        headCells = headCells.filter(headCell => headCell.id !== 'CreatedAt' && headCell.id !== 'UploadedAt' && headCell.id !== 'LastDownloadAt');
    }

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function FileTable({ files }) {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xl'));
    const MAX_TAGS_DISPLAY = 4; // Max number of tags to display directly
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('CreatedAt');
    const [expandedTags, setExpandedTags] = useState({}); // New state to track expanded tags by file ID
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const toggleTagExpansion = (fileId) => {
        setExpandedTags((prevExpandedTags) => ({
            ...prevExpandedTags,
            [fileId]: !prevExpandedTags[fileId], // Toggle expansion state for the given fileId
        }));
    };

    const renderTags = (file) => {
        const isExpanded = expandedTags[file.Id];
        const tags = Object.entries(file.Tags);

        // When tags are expanded or there are few enough tags to always show all
        if (isExpanded || tags.length <= MAX_TAGS_DISPLAY) {
            return (
                <>
                    {tags.map(([key, value]) => (
                        <TagComponent key={key} tagKey={key} tagValue={value} />
                    ))}
                    {tags.length > MAX_TAGS_DISPLAY && (
                        <IconButton onClick={() => toggleTagExpansion(file.Id)} size="small">
                            {t('common.showLess')}
                        </IconButton>
                    )}
                </>
            );
        }

        // When tags are not expanded and there are more than MAX_TAGS_DISPLAY tags
        return (
            <>
                {tags.slice(0, MAX_TAGS_DISPLAY).map(([key, value]) => (
                    <TagComponent key={key} tagKey={key} tagValue={value} />
                ))}
                <Tooltip title={tags.slice(MAX_TAGS_DISPLAY).map(([key, value]) => `${key}: ${value}`).join(', ')}>
                    <IconButton onClick={() => toggleTagExpansion(file.Id)} size="small">
                        +{tags.length - MAX_TAGS_DISPLAY} {t('common.showMore')}

                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const sortArray = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const handleDownload = async (fileId) => {
        try {
            const response = await API.post('/file/initiateFileDownload', { fileId })
            const { DownloadUrl } = response.data;
            const link = document.createElement('a');
            link.href = DownloadUrl;
            link.target = '_blank'; // Open in a new tab
            document.body.appendChild(link);

            link.click();

            // Clean up
            link.remove();
            window.URL.revokeObjectURL(DownloadUrl);
        } catch (error) {
            const message = getErrorMessage(error.response.data, t) || t('files.download.fail')
            enqueueSnackbar(message, { variant: 'warning' });
        }
    };


    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const fullDate = date.toLocaleString();
        const displayDate = date.toLocaleDateString();

        return (
            <Tooltip title={fullDate}>
                <span>{displayDate}</span>
            </Tooltip>
        );
    };

    const sortedfiles = sortArray(files, getComparator(order, orderBy));

    return (
        <TableContainer component={Paper} sx={styles.tableContainer}>
            <Table sx={styles.table} aria-label="customized table" stickyHeader >
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {sortedfiles.length > 0 ? (sortedfiles.map((f) => (
                        <TableRow key={f.Id} sx={styles.tableRow}>
                            <TableCell sx={styles.bodyCellDowload}>
                                <Tooltip title={t('files.download')}>
                                    <Button variant="contained"
                                        component='label'
                                        startIcon={<CloudDownloadIcon />}
                                        style={{ borderRadius: 16, fontSize: '0.7rem' }}
                                        size='small'
                                        onClick={() => handleDownload(f.Id)}
                                        >
                                        {t('files.download')}
                                    </Button>
                                </Tooltip>
                            </TableCell>
                            <TableCell sx={styles.bodyCell}>
                                <Tooltip title={f.Name}>{f.Name}</Tooltip>
                            </TableCell>
                            <TableCell sx={styles.bodyCell}>
                                <Tooltip title={f.OriginalFilePath}>{f.OriginalFilePath}</Tooltip>
                            </TableCell>
                            <TableCell sx={styles.bodyCell}>{f.FileSize ? formatFileSize(f.FileSize) : ''}</TableCell>
                            <TableCell>
                                {f.Tags && renderTags(f)}
                            </TableCell>
                            {!matches && <TableCell sx={styles.bodyCell}>{f.CreatedAt ? formatDate(f.CreatedAt) : ''}</TableCell>}
                            {!matches && <TableCell sx={styles.bodyCell}>{f.UploadedAt ? formatDate(f.UploadedAt) : ''}</TableCell>}
                            {!matches && <TableCell sx={styles.bodyCell}>{f.LastDownloadAt ? formatDate(f.LastDownloadAt) : ''}</TableCell>}
                            <TableCell sx={styles.bodyCell}>{renderStorageClassBadge(f.StorageClass)}</TableCell>
                        </TableRow>
                    ))) :
                        (
                            <TableRow style={styles.noResultRow}>
                                <TableCell colSpan={8}>No files found. Please adjust your search criteria.</TableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default FileTable;
