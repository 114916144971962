import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Box, Button, Container } from '@mui/material';
import PasswordResetForm from '../sections/auth/login/PasswordResetForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: '42rem',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export default function ChangePasswordPage() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  return (
    <>
      <Helmet>
        <title> {t('password.change.title')}</title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="lg">
          <StyledContent>
            <PasswordResetForm />
            <Box mt={5}>
              <Button type='button' variant='outlined' onClick={handleBack} >{t('common.back')}</Button>

            </Box>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
