import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Paper, TextField, Typography } from '@mui/material';
import API from '../../../utils/API';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../../../utils/SnackbarProvider';
import { useTranslation } from 'react-i18next';
import { isValidPassword, passwordCriteria } from '../../../utils/auth/passwordValidation';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function PasswordResetForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  // Check which criteria the password meets
  const passwordFeedback = passwordCriteria.map(criteria => ({
    ...criteria,
    isMet: criteria.test(newPassword),
  }));


  const handlePasswordChange = async () => {
    if (!newPassword || !confirmPassword || newPassword !== confirmPassword || !isValidPassword(newPassword)) {
      if (!isValidPassword(newPassword)) {
        enqueueSnackbar(t('password.reset.validationFail'), { severity: 'warning' });
      } else {
        enqueueSnackbar(t('password.reset.nomatch'), { severity: 'warning' });
      }
      return;
    }

    setLoading(true); // Start loading

    try {
      const response = await API.post('/user/changeMyPassword', {
        Password: newPassword
      });
      if (response.status === 200) {
        enqueueSnackbar(t('password.reset.success'), { severity: 'success' });
        // Wait for 3 seconds before redirecting
        setTimeout(() => {
          navigate('/dashboard');
        }, 3000);
      } else {
        enqueueSnackbar(t('password.reset.fail'), { severity: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(t('password.reset.fail'), { severity: 'error' });
    }
    finally {
      setLoading(false); // Ensure loading is alwasy stopped
    }
  };

  return (
    <Paper elevation={6} style={{ padding: 20, width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item md={12} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h6" gutterBottom>
            {t('password.change.title')}
          </Typography>
          <TextField
            label="New Password"
            variant="outlined"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Confirm New Password"
            variant="outlined"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin="normal"
          />
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handlePasswordChange}
            loading={loading}
            style={{ marginTop: '20px' }}
          >
            {t('common.submit')}
          </LoadingButton>
          <Typography variant='subtitle2' sx={{ mt: 4, color: 'text.secondary' }} >
            {t('password.policy.message')}
          </Typography>
          <List dense sx={{ width: '100%' }}>
            {passwordFeedback.map(({ message, isMet }) => (
              <ListItem key={message} >
                <ListItemIcon>
                  {isMet ? <CheckCircleOutlineIcon color="success" /> : <ErrorOutlineIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary={t(message)} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
}
