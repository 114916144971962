import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


// Import your translation files
import translationEN from '../locales/en/translation.json';
import translationFR from '../locales/fr/translation.json';
import translationIT from '../locales/it/translation.json';
import translationDE from '../locales/de/translation.json';



// the translations
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    it: {
        translation: translationIT
    },
    de: {
        translation: translationDE
    },
};

i18n
    .use(LanguageDetector) // Use the language detector plugin
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: 'en', // not needed, as we are detecting the language
        fallbackLng: 'en', // language to use if detection fails
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie'], // cache user language choice
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
