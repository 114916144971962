import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import OtpQrCode from '../sections/auth/login/OtpQrCode';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: '42rem',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export default function SetupOtpPage() {

  return (
    <>
      <Helmet>
        <title> Otp Setup</title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="lg">
          <StyledContent>
            <OtpQrCode />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
