import { Helmet } from 'react-helmet-async';
import { Box, Container, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import API from '../utils/API';
import { HttpStatusCode } from 'axios';
import GroupTable from '../sections/groups/GroupTable';
import AddGroupButton from '../sections/groups/AddGroupButton';
import CreateGroupModal from '../sections/groups/CreateGroupModal';
import ConfirmationDialog from '../utils/ConfirmationDialog';
import { useSnackbar } from '../utils/SnackbarProvider';
import useIsAdmin from '../hooks/useIsAdmin';
import getErrorMessage from '../utils/errorHandler/GetErrorMessage';
import { useTranslation } from 'react-i18next';

export default function GroupsPage() {
    const [groups, setGroups] = useState({ loading: true, data: [] });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editableGroup, setEditableGroup] = useState(null); // Track the group being edited
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [entityIdToDelete, setEntityIdToDelete] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const isAdmin = useIsAdmin();
    const { t } = useTranslation();

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setEditableGroup(null); // Ensure modal opens in "create" mode by default
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditableGroup(null); // Reset editable group when closing modal
    };

    const handleEdit = (groupId) => {
        const groupToEdit = groups.data.find(group => group.GroupId === groupId);
        if (groupToEdit) {
            setEditableGroup(groupToEdit);
            setIsModalOpen(true);
        }
    };

    const handleDelete = (entityId) => {
        setEntityIdToDelete(entityId);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setEntityIdToDelete(null);
    };

    const handleConfirmDelete = async () => {
        setIsConfirmLoading(true);
        const payload = {
            GroupId: entityIdToDelete
        };
        try {

            await API.post("/group/deleteGroup", payload);
            enqueueSnackbar('Group successfully deleted');
        }
        catch (error) {
            enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'warning' })
        }
        finally {
            setIsConfirmLoading(false);
            handleCloseDialog();
            refreshGroups();
        }
    };

    const refreshGroups = async () => {
        const response = await API.get("/group/listGroups");
        if (response.status === HttpStatusCode.Ok) {
            setGroups({
                data: response.data,
                loading: false
            });
        }
    };

    useEffect(() => {
        const getData = async () => {

            try{
                const response = await API.get("/group/listGroups");
                if (response.status === HttpStatusCode.Ok) {
                    setGroups({
                        data: response.data,
                        loading: false
                    })
                }
            }
            catch(error){
                setGroups({
                    data: [],
                    loading: false
                })
                enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'warning' })
            }

        }

        getData();
    }, [])

    return (
        <>
            <Helmet>
                <title>{t('group.title')}</title>
            </Helmet>
            <Container maxWidth='xl'>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        {t('group.title')}
                    </Typography>
                    <AddGroupButton onClick={handleOpenModal} disabled={!isAdmin} />
                </Box>
                {!groups.loading && (<CreateGroupModal open={isModalOpen} handleClose={handleCloseModal} group={editableGroup} onRefresh={refreshGroups} existingGroupNames={groups.data.map(g => g.GroupName)} />)}
                <ConfirmationDialog
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    onConfirm={handleConfirmDelete}
                    title={t('common.confirmDeletion')}
                    loading={isConfirmLoading}
                >
                    {t('dialog.confirm_delete')}
                </ConfirmationDialog>
                {groups.loading ? (
                    <Box>
                        {Array.from(new Array(5)).map((_, index) => (
                            <Box key={index} display="flex" mb={1}>
                                <Skeleton variant="text" width="40%" height={30} />
                                <Skeleton variant="rectangular" width="60%" height={60} style={{ marginLeft: 16 }} />
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <GroupTable
                        groups={groups.data}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                )}
            </Container>
        </>
    );
}