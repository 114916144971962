import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, FormControlLabel, Checkbox, Box, IconButton, Accordion, AccordionSummary, AccordionDetails, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import API from '../../../utils/API';
import { LoadingButton } from '@mui/lab';
import { useConfig } from '../../../utils/ConfigContext';
import { useSnackbar } from '../../../utils/SnackbarProvider';
import getErrorMessage from '../../../utils/errorHandler/GetErrorMessage';


const ColoredAccordionSummary = styled(AccordionSummary)(({ theme, shouldBeColored }) => ({
    backgroundColor: shouldBeColored ? '#c8e6c9' : 'inherit',
}));

const TagConfigurationStep = ({ configuration, handleTagChange, handleAddTag, handleRemoveTag }) => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const { enqueueSnackbar } = useSnackbar();

    const [urlError, setUrlError] = useState(false);
    const [testInput, setTestInput] = useState('');
    const [matchResults, setMatchResults] = useState({ data: {}, loading: false });
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileContentBase64, setFileContentBase64] = useState('');

    const [AIApiResponse, setAIApiResponse] = useState({ data: {}, loading: false });


    const handleUrlChange = (e) => {
        const { value } = e.target;
        validateUrl(value); // Validate the URL
        handleTagChange('ApiUrlForTags', undefined, undefined, value);
    };
    const validateUrl = (value) => {
        try {
            new URL(value);
            setUrlError(false); // URL is valid
        } catch (e) {
            setUrlError(true);
        }
    };

    // Function to handle file selection and read the file
    const handleFileChange = (e) => {
        setSelectedFile(null); // reset selection

        const file = e.target.files[0];
        e.target.value = null;

        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
                setFileContentBase64(base64String);

            };
            reader.readAsDataURL(file);
        }
    };

    // Function to invoke API with filename, base64 encoded file content, and AiTagsArray
    const handleTestAiTagsConfiguration = async () => {


        const tagsObject = configuration.AiTagsArray && configuration.AiTagsArray.reduce((obj, tag) => {
            if (tag.Key) obj[tag.Key] = { value: tag.Value, multiValue: tag.MultiValue };
            return obj;
        }, {});

        if (selectedFile && fileContentBase64) {

            setAIApiResponse({ loading: true, data: {} })

            const payload = {
                ApiKey: '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',
                FileName: selectedFile.name,
                FileContent: fileContentBase64,
                Tags: tagsObject
            };

            try {
                const response = await API.post('/agent/testAiConfig', payload);
                setAIApiResponse({
                    loading: false, data: response.data
                })
            }
            catch (error) {
                setAIApiResponse({ loading: false, data: {} })
                enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'error' });
            }
            finally {
                setAIApiResponse(prevState => ({ loading: false, ...prevState }));
            }

            setFileContentBase64(null); // reset
        }
    };

    const shouldAccordionBeColored = (option) => {
        switch (option) {
            case 'staticTags':
                return configuration.StaticTagsArray.length > 0;
            case 'regularExpressions':
                return configuration.RegexsArray.length > 0;
            case 'readTagsFromJson':
                return !!configuration.ReadTagsFromJson;
            case 'apiUrlForTags':
                return configuration.ApiUrlForTags.length && configuration.ApiUrlForTags.length > 0;
            case 'aiBasedtags':
                return configuration.AiTagsArray && configuration.AiTagsArray.length > 0;
            default:
                return false;
        }
    };

    const renderStaticTagsOption = () => (
        <>
            {configuration.StaticTagsArray.map((tag, index) => (
                <Box key={index} display="flex" alignItems="center" gap={2} sx={{ mb: 1 }}>
                    <TextField
                        label="Tag Key"
                        value={tag.Key}
                        onChange={(e) => handleTagChange('StaticTagsArray', index, 'Key', e.target.value)}
                        required
                        inputProps={{
                            maxLength: 40
                        }}
                    />
                    <TextField
                        label="Tag Value"
                        value={tag.Value}
                        onChange={(e) => handleTagChange('StaticTagsArray', index, 'Value', e.target.value)}
                        required
                    />
                    <IconButton onClick={() => handleRemoveTag('StaticTagsArray', index)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))}
            <Button variant='outlined' onClick={() => handleAddTag('StaticTagsArray')} sx={{ width: '100%' }}>{t('pathConfiguration.addStaticTag')}</Button>

        </>
    );

    const handleRegexTestChange = (testInput) => {
        setTestInput(testInput);
    }

    const Match = ({ label, error }) => {

        if (Array.isArray(label)) {
            label = label.join(", ")
        }

        return <Chip
            label={label}
            variant="outlined"
            size="medium"

            style={{ margin: '2px', fontWeight: "bold", backgroundColor: error ? "#888" : "#0e9e9d", color: "#ffffff", display: 'flex', flex: 0.2 }}
        />
    }

    const renderAiTagsOption = () => (
        <>
            {configuration.AiTagsArray.map((tag, index) => (
                <Box key={index} display="flex" alignItems="center" gap={1} sx={{ mb: 1 }}>
                    <TextField
                        label="Tag Key"
                        value={tag.Key}
                        onChange={(e) => handleTagChange('AiTagsArray', index, 'Key', e.target.value)}
                        required
                        inputProps={{
                            maxLength: 40
                        }}
                        sx={{
                            display: 'flex',
                            flex: 0.4
                        }}
                    />
                    <TextField
                        label="Question"
                        value={tag.Value}
                        onChange={(e) => handleTagChange('AiTagsArray', index, 'Value', e.target.value)}
                        required
                        sx={{
                            display: 'flex',
                            flex: 1
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tag.MultiValue}
                                onChange={(e) => handleTagChange('AiTagsArray', index, 'MultiValue', e.target.checked)}
                            />
                        }
                        label="Multi-Value"
                        sx={{
                            display: 'flex',
                            flex: 0.4
                        }}
                    />
                    <IconButton
                        onClick={() => handleRemoveTag('AiTagsArray', index)}
                        color="error"
                        sx={{
                            display: 'flex',
                            flex: 0.1
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                    {(selectedFile && !AIApiResponse.loading) &&

                        (AIApiResponse.data && AIApiResponse.data[tag.Key] ? (
                            <Match label={AIApiResponse.data[tag.Key]} />
                        ) : (
                            <Match label={t('pathConfiguration.noMatch')} error />
                        ))}
                </Box>
            ))}
            <Button variant='outlined' onClick={() => handleAddTag('AiTagsArray')} sx={{ width: '100%' }}>{t('pathConfiguration.addAiTag')}</Button>
            <Box mt={1}  >
                <input
                    accept="*/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={handleFileChange}
                />
                <label htmlFor="raised-button-file">
                    <LoadingButton loading={AIApiResponse.loading} variant="contained" component="span" sx={{ mr: 1 }} disabled={configuration.AiTagsArray && configuration.AiTagsArray.length === 0}>
                        {t('pathConfiguration.selectFile')}
                    </LoadingButton>
                </label>
            </Box>
        </>
    );

    const renderRegExOption = () => (
        <>
            {configuration.RegexsArray.map((regexp, index) => (
                <Box key={index} mt={0.1} display="flex" alignItems="center" gap={2}>
                    <TextField
                        label={`${t('pathConfiguration.tagName')} #${index + 1}`}
                        value={regexp.TagName}
                        onChange={(e) => handleTagChange('RegexsArray', index, 'TagName', e.target.value)}
                        sx={{
                            display: 'flex',
                            flex: 0.4
                        }}
                        margin="dense"
                    />
                    <TextField
                        label={`${t('pathConfiguration.regularExpression')} #${index + 1}`}
                        value={regexp.RegExp}
                        onChange={(e) => handleTagChange('RegexsArray', index, 'RegExp', e.target.value)}
                        sx={{
                            display: 'flex',
                            flex: 1
                        }}
                        margin="dense"
                    />
                    <IconButton onClick={() => { setMatchResults({ data: {} }); handleRemoveTag('RegexsArray', index); }} color="error" sx={{
                        display: 'flex',
                        flex: 0.1
                    }}>
                        <DeleteIcon />
                    </IconButton>
                    {
                        (testInput && !matchResults.loading && Object.keys(matchResults.data).length > 0 && matchResults.data[regexp.TagName]) ? (
                            <Match label={matchResults.data[regexp.TagName]} />
                        ) : (
                            Object.keys(matchResults.data).length > 0 ? (<Match label={t('pathConfiguration.noMatch')} error />) : null
                        )
                    }
                </Box>
            ))}
            <Button variant='outlined' fullWidth onClick={() => { setMatchResults({ data: {} }); handleAddTag('RegexsArray'); }} sx={{ mt: 2, }}>{t('pathConfiguration.addRegularExpression')}</Button>
            <Box mt={1} display='flex' gap={2} >
                <TextField
                    label={`${t('pathConfiguration.testInput')}`}
                    placeholder={`${t('pathConfiguration.testInputPlaceholder')}`}
                    value={testInput}
                    onChange={(e) => handleRegexTestChange(e.target.value)}
                    sx={{
                        display: 'flex',
                        flex: 0.8
                    }}
                    margin="dense"
                />
                <LoadingButton
                    loading={matchResults.loading}
                    disabled={configuration.RegexsArray.length === 0 || !testInput}
                    variant='contained'
                    onClick={() => testRegex()}
                    sx={{
                        display: 'flex',
                        flex: 0.2
                    }}
                >{t('pathConfiguration.runTestInput')}</LoadingButton>
            </Box>

        </>
    );

    const renderJsonOption = () => (
        <FormControlLabel
            control={<Checkbox checked={configuration.ReadTagsFromJson} onChange={(e) => handleTagChange('ReadTagsFromJson', undefined, undefined, e.target.checked)} name="readTagsFromJson" />}
            label={t('pathConfiguration.readTagsFromJson')}
        />
    );


    const renderUrlOption = () => (
        <TextField
            name="ApiUrlForTags"
            label={t('pathConfiguration.apiUrlForTags')}
            value={configuration.ApiUrlForTags}
            onChange={handleUrlChange}
            fullWidth
            margin="normal"
            error={urlError}
            helperText={urlError ? t('pathConfiguration.invalidUrl') : ''}
        />
    );

    const testRegex = async () => {
        const regexObject = configuration.RegexsArray && configuration.RegexsArray.reduce((obj, tag) => {
            if (tag.TagName) obj[tag.TagName] = tag.RegExp;
            return obj;
        }, {});

        setMatchResults({ loading: true, data: {} })


        try {
            const payload = {
                TestInput: testInput,
                Regexs: regexObject
            };

            const response = await API.post('/agent/testRegexConfig', payload);
            setMatchResults({
                loading: false, data: response.data
            })
        }
        catch (err) {
            console.warn(err);
        }
        finally {
            setMatchResults(prevState => ({ loading: false, ...prevState }));
        }
    }

    useEffect(() => { handleTestAiTagsConfiguration(); }, [fileContentBase64]);

    return (
        <Box sx={{ width: '100%', mt: 2 }}>
            <Accordion>
                <ColoredAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    shouldBeColored={shouldAccordionBeColored('staticTags')}
                >                    <Box>
                        <Typography>
                            {t('pathConfiguration.accordion.staticOpt')}</Typography>
                        <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
                            {t('pathConfiguration.tooltips.staticOpt')}
                        </Typography>
                    </Box>
                </ColoredAccordionSummary>
                <AccordionDetails>
                    {renderStaticTagsOption()}
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <ColoredAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    shouldBeColored={shouldAccordionBeColored('regularExpressions')}>
                    <Box>
                        <Typography>
                            {t('pathConfiguration.accordion.regexpOpt')}</Typography>
                        <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
                            {t('pathConfiguration.tooltips.regexpOpt')}
                        </Typography>
                        <Typography variant='caption' sx={{ color: 'text.secondary' }}>
                            {t('pathConfiguration.tooltips.regexpOpt2')}
                        </Typography>
                    </Box>
                </ColoredAccordionSummary>
                <AccordionDetails>
                    {renderRegExOption()}
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <ColoredAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    shouldBeColored={shouldAccordionBeColored('readTagsFromJson')}
                >                     <Box>
                        <Typography>
                            {t('pathConfiguration.accordion.jsonOpt')}</Typography>
                        <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
                            {t('pathConfiguration.tooltips.jsonOpt')}
                        </Typography>
                    </Box>
                </ColoredAccordionSummary>
                <AccordionDetails>
                    {renderJsonOption()}
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <ColoredAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    shouldBeColored={shouldAccordionBeColored('apiUrlForTags')}
                >                     <Box>
                        <Typography>
                            {t('pathConfiguration.accordion.urlOpt')}</Typography>
                        <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
                            {t('pathConfiguration.tooltips.urlOpt')}
                        </Typography>
                    </Box>
                </ColoredAccordionSummary>
                <AccordionDetails>
                    {renderUrlOption()}
                </AccordionDetails>
            </Accordion>

            {config && config.featureToggle && config.featureToggle.aiTags && (<Accordion>
                <ColoredAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    shouldBeColored={shouldAccordionBeColored('aiBasedtags')}
                >
                    <Box>
                        <Typography>
                            {t('pathConfiguration.accordion.aiOpt')}</Typography>
                        <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
                            {t('pathConfiguration.tooltips.aiOpt')}
                        </Typography>
                    </Box>
                </ColoredAccordionSummary>
                <AccordionDetails>
                    {renderAiTagsOption()}
                </AccordionDetails>
            </Accordion>)
            }
        </Box>
    );
};

export default TagConfigurationStep;
