import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DeleteConfirmationDialog = ({ open, onClose, onConfirm, agentName }) => {
    const [confirmationText, setConfirmationText] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (!open) {
            setConfirmationText('');
        }
    }, [open]);

    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    const isConfirmDisabled = confirmationText !== t('common.deleteMe');

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="delete-confirmation-dialog-title">
            <DialogTitle id="delete-confirmation-dialog-title">{`Confirm Deletion of ${agentName}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('common.deleteMeMessage')}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                    placeholder={t('common.deleteMe')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('common.cancel')}</Button>
                <Button onClick={handleConfirm} disabled={isConfirmDisabled} color="error">
                    {t('common.delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationDialog;
