// AgentCard.js
import React from 'react';
import { Card, CardContent, Typography, Chip, CardActions, IconButton, Tooltip, Box } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useTranslation } from 'react-i18next';
import ReportIcon from '@mui/icons-material/Report';

const AgentCard = ({ agent, onEdit, onDelete, onShowLogs, onDownload }) => {
    const { t } = useTranslation();


    const { Id, Name, LastCommandReceivedAt, IsActive, CurrentAgentVersion, SWVersion } = agent;
    const lastCommandDate = LastCommandReceivedAt && LastCommandReceivedAt > 0 ? new Date(LastCommandReceivedAt).toLocaleString() : t('agents.never');


    const isAgentUpdated = CurrentAgentVersion === -1 || (Id === CurrentAgentVersion); // CurrentAgentVersion === -1 means that the agent was never started

    return (
        <Card sx={{ maxWidth: 300, m: 2, borderRadius: 2, overflow: 'hidden', boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)" }}>
            <CardContent>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant="h5" color="text.primary" noWrap >
                        {Name}
                    </Typography>
                    {!isAgentUpdated && <Tooltip title={t('agents.configurationMismatch')}>
                        <ReportIcon fontSize='large' color='warning' />
                    </Tooltip>}
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {t('agents.lastSeenAt')}: {lastCommandDate}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {t('agents.swVersion')}: {SWVersion}
                </Typography>
            </CardContent>
            <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <Tooltip title={t('agents.downloadConfiguration')}>
                        <IconButton aria-label="download" onClick={onDownload}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('agents.editConfiguration')}>
                        <IconButton aria-label="edit" onClick={onEdit}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('agents.deleteConfiguration')}>
                        <IconButton aria-label="delete" onClick={onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('agents.logConfiguration')}>
                        <IconButton aria-label="logs" onClick={onShowLogs}>
                            <ListAltIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100%' }}>
                    <Chip
                        label={IsActive ? 'Online' : 'Offline'}
                        color={IsActive ? 'success' : 'error'}
                        size="small"
                    />
                </Box>
            </CardActions>
        </Card>
    );
};

export default AgentCard;
