import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Iconify from '../../../components/iconify';
import { useSnackbar } from '../../../utils/SnackbarProvider';
import API from '../../../utils/API';
import { HttpStatusCode } from 'axios';
import getErrorMessage from '../../../utils/errorHandler/GetErrorMessage';

export default function OtpQrCode() {
  const [verificationCode, setVerificationCode] = useState(''); // State to hold the verification code
  const [verificationCodeError,] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { otpCodeUri, email } = location.state || {};

  if (!otpCodeUri) {
    // Redirect back or show an error if no URI is provided
    navigate('/login');
    return null;
  }

  const verifyCode = async () => {
    try {
      const response = await API.post('/auth/verifyOtp', { VerificationCode: verificationCode, Email: email });

      if (response.status === HttpStatusCode.Ok) {
        navigate('/change-password'); // ok move to next step
      }
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'error' });
      //   alert('There was a problem with your fetch operation:', error.message);
    }
  };

  const handleConfirmation = () => {
    verifyCode();
  };


  return (
    <Paper elevation={6} style={{ padding: '20px', margin: '20px', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item md={6} display="flex" justifyContent="center" alignItems="center">
          <Box
            textAlign="center"
            justifyContent="center"
            component="img"
            src="/assets/permastore_logo_vertical.png"
            sx={{ height: 275 }}
          />
        </Grid>
        <Grid item md={6} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h6" gutterBottom>
            {t('qrcode.scan.title')}
          </Typography>
          <QRCodeSVG value={otpCodeUri} size={128} level={"H"} includeMargin={true} />
          <Typography variant="body2" marginTop={2} marginBottom={2}>
            {t('qrcode.scan.help')}
          </Typography>
          <TextField

            name="code"
            label={t('login.verificationCode')}
            placeholder="123 456" // Placeholder text
            type="text"
            inputProps={{
              maxLength: 6, // Restrict input length to 6 characters
              pattern: "\\d{6}", // Ensure it's 6 digits (note: pattern is more for validation)
            }}
            fullWidth
            value={verificationCode}
            error={verificationCodeError}
            helperText={verificationCodeError ? t("login.invalidCode") : t('login.enterCode')} // Replace both instances of helperText with translation keys
            onChange={(event) => {
              // Ensure only numbers are allowed and limit to 6 characters
              const val = event.target.value.slice(0, 6).replace(/[^0-9]/g, '');
              setVerificationCode(val);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="ant-design:lock-filled" />
                </InputAdornment>
              ),
            }}
          />
          <Button disabled={!verificationCode} variant="contained" color="primary" onClick={handleConfirmation} style={{ marginTop: '20px' }}>
            {t('qrcode.scan.confirm')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
