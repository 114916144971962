import React from 'react';
import { Chip } from '@mui/material';

const TagComponent = ({ tagKey, tagValue, onDelete }) => {
    return (
        <Chip
            label={`${tagKey}: ${tagValue} `}
            variant="outlined"
            size="medium"
            style={{ margin: '2px', fontWeight: "bold", backgroundColor: "#0e9e9d", color: "#ffffff" }}
            onDelete={onDelete}
        />
    );
};

export default TagComponent;
