import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Paper, IconButton, CircularProgress, Autocomplete, Checkbox, FormControlLabel, DialogActions, DialogContentText, DialogContent, DialogTitle, Dialog } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import API from '../../utils/API';
import { useSnackbar } from '../../utils/SnackbarProvider';
import { HttpStatusCode } from 'axios';
import { useTranslation } from 'react-i18next';
import getErrorMessage from '../../utils/errorHandler/GetErrorMessage';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function CreateGroupModal({ open, handleClose: closeHandler, group, onRefresh, existingGroupNames }) {
    const [groupName, setGroupName] = useState('');
    const [tags, setTags] = useState([{ key: '', value: '' }]);
    const [groupId, setGroupId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [tagNames, setTagNames] = useState({ loading: false, data: [] });

    const [viewAllFiles, setViewAllFiles] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmInput, setConfirmInput] = useState('');

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        if (group && open) {
            setGroupName(group.GroupName || '');
            setTags(group.Tags ? Object.entries(group.Tags).map(([key, value]) => ({ key, value })) : [{ key: '', value: '' }]);
            setGroupId(group.GroupId || null);
            setViewAllFiles(Object.keys(group.Tags).length === 0 || false);
        }
    }, [group, open]);

    const handleViewAllFilesChange = (event) => {
        setViewAllFiles(event.target.checked);
    };

    const handleCloseConfirmDialog = () => {
        setConfirmDialogOpen(false);
        setConfirmInput('');
        if (confirmInput.toLowerCase() !== 'view all') {
            setViewAllFiles(false);
        }
    };

    const handleConfirm = async () => {
        if (confirmInput.toLowerCase() === 'view all') {
            setConfirmDialogOpen(false);
            await submitForm(); // Proceed to submit the form after confirmation.
        } else {
            enqueueSnackbar(t('You must type "view all" to confirm.'), { variant: 'warning' });
        }
    };

    const handleChangeConfirmInput = (event) => {
        setConfirmInput(event.target.value);
    };

    const handleClose = () => {
        setGroupName('');
        setTags([{ key: '', value: '' }]);
        setGroupId(null);
        setViewAllFiles(false);
        setConfirmInput('');
        closeHandler();
    };

    const handleAddTag = () => {
        setTags([...tags, { key: '', value: '' }]);
    };

    const handleRemoveTag = (index) => {
        const newTags = tags.filter((_, i) => i !== index);
        setTags(newTags);
    };

    const handleChangeTag = (index, field, value) => {
        const newTags = tags.map((tag, i) => {
            if (i === index) {
                return { ...tag, [field]: value };
            }
            return tag;
        });
        setTags(newTags);
    };

    const isValidGroupName = () => {
        return !existingGroupNames.includes(groupName.trim()) || groupId;
    }


    const handleSubmit = async () => {
        if (viewAllFiles) {
            // If viewAllFiles is checked, prompt for confirmation before proceeding.
            setConfirmDialogOpen(true);
        } else {
            // If viewAllFiles is not checked, proceed to submit the form directly.
            await submitForm();
        }
    };

    const submitForm = async () => {

        if (!isValidGroupName) {
            return;
        }

        if (!isValidForm()) {
            enqueueSnackbar(t('common.validation.provideGroupNameAndTag'), { severity: 'warning' });
            return;
        }

        setIsLoading(true); // Start loading
        const tagsObject = tags.reduce((obj, tag) => {
            if (tag.key) obj[tag.key] = tag.value;
            return obj;
        }, {});

        let payload = {
            groupName,
            tags: tagsObject,
        };

        try {
            if (groupId) {
                payload.groupId = groupId;
                await API.post("/group/editGroup", payload);
            } else {
                await API.post("/group/addGroup", payload);
            }
            onRefresh(); // Call the refresh callback
            handleClose(); // Reset and close the modal after submission
        } catch (error) {
            enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'warning' })

        } finally {
            setIsLoading(false); // Stop loading regardless of the outcome
        }
    };

    useEffect(() => {
        const loadTagNames = async () => {
            setTagNames({ loading: true });
            try {
                const response = await API.get("/file/listTagNames");
                if (response.status === HttpStatusCode.Ok) {
                    setTagNames({
                        data: response.data.map(t => t.Name),
                        loading: false
                    });
                } else {
                    setTagNames({ loading: false });
                }
            } catch (error) {
                enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'warning' })
            }
        };

        loadTagNames();
        // eslint-disable-next-line
    }, []);


    const renderConfirmDialog = () => (
        <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
            <DialogTitle>{t('Confirm')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('To confirm that you want to allow this group to view all files, type "view all".')}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="confirmation"
                    placeholder={t('Type "view all" here')}
                    type="text"
                    fullWidth
                    variant="standard"
                    value={confirmInput}
                    onChange={handleChangeConfirmInput}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseConfirmDialog}>{t('Cancel')}</Button>
                <Button onClick={handleConfirm} disabled={confirmInput.toLowerCase() !== 'view all'}>{t('Confirm')}</Button>
            </DialogActions>
        </Dialog>
    );

    const isValidForm = () => {
        const isGroupNameValid = groupName.trim().length > 0;
        // If viewAllFiles is true, having 0 tags is okay. Otherwise, check if at least one tag is valid.
        const isAtLeastOneTagValid = viewAllFiles || tags.some(tag => tag.key.trim().length > 0 && tag.value.trim().length > 0);

        return isGroupNameValid && isAtLeastOneTagValid;
    };



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="create-group-modal"
            aria-describedby="modal-modal-description"
        >
            <Paper sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {groupId ? t('common.editGroup') : t('common.createNewGroup')}
                </Typography>
                <TextField
                    fullWidth
                    label={t('common.groupName')}
                    value={groupName}
                    disabled={!!groupId}
                    onChange={(e) => setGroupName(e.target.value)}
                    margin="normal"
                    error={!isValidGroupName()}
                    helperText={!isValidGroupName() ? t('common.validation.groupNameExists') : ''}

                />
                <FormControlLabel
                    control={<Checkbox checked={viewAllFiles} onChange={handleViewAllFilesChange} />}
                    label={t('Allow this group to view all files')}
                />
                {!tagNames.loading && !viewAllFiles && tags.map((tag, index) => (
                    <Box key={index} display="flex" alignItems="center" mt={2}>
                        <Autocomplete
                            freeSolo
                            options={tagNames.data}
                            value={tag.key}
                            onChange={(event, newValue) => handleChangeTag(index, 'key', newValue)}
                            onInputChange={(event, newInputValue) => {
                                if (event && event.type !== 'blur') {
                                    handleChangeTag(index, 'key', newInputValue);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label={t('common.key')} variant="outlined" style={{ flex: 1 }} />
                            )}
                            sx={{ flex: 1, mr: 1 }}
                            filterOptions={(options, { inputValue }) => {
                                return options.filter(option => option.toLowerCase().includes(inputValue.toLowerCase())).slice(0, 5);
                            }}
                        />
                        <TextField
                            label={t('common.value')}
                            value={tag.value}
                            onChange={(e) => handleChangeTag(index, 'value', e.target.value)}
                            sx={{ flex: 1 }}
                        />

                        <IconButton onClick={() => handleAddTag()}>
                            <AddCircleOutlineIcon />
                        </IconButton>
                        {tags.length > 1 && (
                            <IconButton onClick={() => handleRemoveTag(index)}>
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                        )}
                    </Box>
                ))}
                {
                    tagNames.loading && !viewAllFiles && (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress size={24} />
                    </div>)

                }
                {renderConfirmDialog()}

                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isLoading}
                    sx={{ mt: 2, mr: 1 }}
                >
                    {isLoading ? <CircularProgress size={24} /> : groupId ? t('common.updateGroup') : t('common.createGroup')}
                </Button>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                    sx={{ mt: 2 }}
                >
                    {t('common.cancel')}
                </Button>
            </Paper>
        </Modal>
    );
}

export default CreateGroupModal;
