import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../utils/SnackbarProvider';
import TagComponent from '../../utils/tags/TagComponent';
import { useAuth } from '../../utils/auth/AuthProvider';

function QueryBankManager({ tags, setTags }) {
    const [queryName, setQueryName] = useState('');
    const [savedQueries, setSavedQueries] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [filterText, setFilterText] = useState('');

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuth();
    const queryBankItemKey = `permastore_queryBank-${user.UserId}`

    useEffect(() => {
        loadSavedQueries();
    }, []);

    const handleOpenSaveDialog = () => {
        setOpenSaveDialog(true);
    };

    const saveQueryToBank = () => {
        if (!queryName.trim()) {
            enqueueSnackbar(t('files.enter_query_name'), { variant: 'warning' });
            return;
        }

        let queryBank = JSON.parse(localStorage.getItem(queryBankItemKey)) || { queries: [] };
        if (queryBank.queries.some(q => q.name === queryName)) {
            enqueueSnackbar(t('files.query_name_exists'), { variant: 'error' });
            return;
        }

        queryBank.queries.push({ name: queryName, tags });
        localStorage.setItem(queryBankItemKey, JSON.stringify(queryBank));
        enqueueSnackbar(t('files.query_saved'), { variant: 'success' });
        loadSavedQueries();
        setQueryName('');
        setOpenSaveDialog(false);
    };

    const loadQueryFromBank = (queryName) => {
        const queryBank = JSON.parse(localStorage.getItem(queryBankItemKey));
        const query = queryBank.queries.find(q => q.name === queryName);
        if (query) {
            setTags(query.tags);
        }
        setOpenDialog(false);
    };

    const loadSavedQueries = () => {
        const queryBank = JSON.parse(localStorage.getItem(queryBankItemKey)) || { queries: [] };
        setSavedQueries(queryBank.queries);
    };

    const deleteQuery = (queryName) => {
        let queryBank = JSON.parse(localStorage.getItem(queryBankItemKey));
        queryBank.queries = queryBank.queries.filter(q => q.name !== queryName);
        localStorage.setItem(queryBankItemKey, JSON.stringify(queryBank));
        enqueueSnackbar(t('files.query_deleted'), { variant: 'success' });
        loadSavedQueries();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Box display='flex' alignItems='flex-end' justifyContent='flex-end' mt={2}>
            <Button
                variant="contained"
                color='primary'
                startIcon={<DeleteSweepIcon />}
                onClick={() => setTags([])}
                disabled={tags.length === 0}
                style={{ marginRight: 10 }}
            >
                {t('files.remove_filters')}
            </Button>
            <Button
                variant='contained'
                color='primary'
                startIcon={<SaveIcon />}
                onClick={handleOpenSaveDialog}
                disabled={tags.length === 0}
                style={{ marginRight: 10 }}
            >
                {t('files.save_query')}
            </Button>
            <Button
                variant="contained"
                color='primary'
                startIcon={<OpenInNewIcon />}
                onClick={() => setOpenDialog(true)}
                disabled={savedQueries.length === 0}
            >
                {t('files.load_query')}
            </Button>

            {/* Save Query Dialog */}
            <Dialog open={openSaveDialog} onClose={() => setOpenSaveDialog(false)}>
                <DialogTitle>{t('files.save_query')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('files.query_name')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={queryName}
                        onChange={(e) => setQueryName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSaveDialog(false)}>{t('common.cancel')}</Button>
                    <Button onClick={saveQueryToBank}>{t('common.save')}</Button>
                </DialogActions>
            </Dialog>

            {/* Load Query Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
                <DialogTitle>{t('files.saved_queries')}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label={t('files.filter_queries')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        style={{ marginBottom: '20px' }}
                    />
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('files.query_name')}</TableCell>
                                <TableCell>{t('files.tags')}</TableCell>
                                <TableCell align="right">{t('files.actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {savedQueries.filter(query => query.name.toLowerCase().includes(filterText.toLowerCase())).map((query) => (
                                <TableRow key={query.name}>
                                    <TableCell>{query.name}</TableCell>
                                    <TableCell>
                                        {query.tags && query.tags.map(({ key, value }) => (
                                            <TagComponent key={key} tagKey={key} tagValue={value} />
                                        ))}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton color='primary' onClick={() => loadQueryFromBank(query.name)}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                        <IconButton onClick={() => deleteQuery(query.name)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>{t('common.close')}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default QueryBankManager;
