import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Container, TextField, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PathConfigurations from "../sections/agents/PathConfigurations";
import PathConfigurationModal from "../sections/agents/PathConfigurationModal";
import API from "../utils/API";
import { useSnackbar } from "../utils/SnackbarProvider";
import getErrorMessage from "../utils/errorHandler/GetErrorMessage";

const AgentDetailPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [agent, setAgent] = useState({ Name: "", PathConfigurations: [] }); // Default to an "empty" agent for creation
    const [loading, setLoading] = useState(false);


    const isEdit = id && id > 0;

    const fetchAgent = async (agentId) => {
        setLoading(true);
        try {
            const { data } = await API.get('/agent/listAgents?id=' + agentId);
            const pathConfigurations = data.Paths.map(config => {
                const staticTagsArray = Object.entries(config.StaticTags).map(([key, value]) => ({ Key: key, Value: value }));
                const regexsArray = Object.entries(config.Regexs).map(([key, value]) => ({ TagName: key, RegExp: value }));
                const aiTags = config.AiTags ? Object.entries(config.AiTags).map(([key, value]) => ({ Key: key, Value: value.value, MultiValue: value.multiValue })) : [];

                return { ...config, StaticTagsArray: staticTagsArray, RegexsArray: regexsArray, AiTagsArray: aiTags };
            });

            setAgent({ Name: data.Name, Status: data.Status, PathConfigurations: pathConfigurations });
        } catch (error) {
            enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'warning' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isEdit) {
            fetchAgent(id);
        }
        // eslint-disable-next-line
    }, [id, isEdit]);

    const handleChange = (event) => {
        setAgent({ ...agent, Name: event.target.value });
    };


    // State for managing modal open state and the configuration being edited
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentConfig, setCurrentConfig] = useState(null);

    const handleOpenModalForNew = () => {
        setCurrentConfig(null); // No initial configuration for a new addition
        setIsModalOpen(true);
    };

    const handleOpenModalForEdit = (configIndex) => {
        setCurrentConfig(agent.PathConfigurations[configIndex]); // Pass the configuration to be edited
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSaveConfiguration = (newOrUpdatedConfig, prevName) => {
        let pathConfigurations = [...agent.PathConfigurations];

        if (newOrUpdatedConfig.AiTagsArray) {
            let aiTagsObj = {};
            newOrUpdatedConfig.AiTagsArray.forEach(tag => {
                if (tag.Key && tag.Value) { // Only add if both TagName and Value are present
                    aiTagsObj[tag.Key] = { value: tag.Value, multiValue: tag.MultiValue }; // Include multiValue flag
                }
            });

            if (!Array.isArray(newOrUpdatedConfig.ProvidersConfigurations)) {
                newOrUpdatedConfig.ProvidersConfigurations = [];
            }

            const providerId = 1; // AI ProviderId

            // Find existing provider configuration by ProviderId
            const existingConfigIndex = newOrUpdatedConfig.ProvidersConfigurations.findIndex(config => config.ProviderId === providerId);

            if (existingConfigIndex !== -1) {
                // Update existing configuration
                newOrUpdatedConfig.ProvidersConfigurations[existingConfigIndex].Config = JSON.stringify({
                    Tags: aiTagsObj
                });
            } else {
                // Add new configuration
                newOrUpdatedConfig.ProvidersConfigurations.push({
                    ProviderId: providerId,
                    Config: JSON.stringify({
                        Tags: aiTagsObj
                    })
                });
            }
        }

        // Use prevName to find the existing configuration if it's a rename,
        // otherwise, find by the new configuration's name.
        let existingConfigIndex = -1;
        if (prevName && prevName !== newOrUpdatedConfig.Name) {
            existingConfigIndex = pathConfigurations.findIndex(config => config.Name === prevName);
        } else {
            existingConfigIndex = pathConfigurations.findIndex(config => config.Name === newOrUpdatedConfig.Name);
        }

        if (existingConfigIndex !== -1) {
            // If it's an existing configuration, update it
            pathConfigurations[existingConfigIndex] = newOrUpdatedConfig;
        } else {
            // If it's a new configuration, add it
            pathConfigurations.push(newOrUpdatedConfig);
        }

        // Update the agent state
        setAgent(prevAgent => ({
            ...prevAgent,
            PathConfigurations: pathConfigurations
        }));

        // Close the modal and reset the current configuration
        setIsModalOpen(false);
        setCurrentConfig(null);
    };



    const isFormValid = () => {
        return agent.Name && agent.PathConfigurations.length > 0;
    };

    const goBack = () => {
        navigate('/dashboard/agents');
    }

    const submitChanges = async () => {

        setLoading(true); // Disable UI interactions

        agent.PathConfigurations = agent.PathConfigurations.map(config => {
            // Reduce staticTags to an object
            let staticTagsObj = {};
            config.StaticTagsArray.forEach(tag => {
                staticTagsObj[tag.Key] = tag.Value;
            });

            // Reduce regularExpressions to an object with RegExp as value and TagName as key
            let regularExpressionsObj = {};
            config.RegexsArray.forEach(expr => {
                if (expr.TagName && expr.RegExp) { // Only add if both TagName and RegExp are present
                    regularExpressionsObj[expr.TagName] = expr.RegExp;
                }
            });

            let aiTagsObj = {};
            config.AiTagsArray.forEach(tag => {
                if (tag.Key && tag.Value) { // Only add if both TagName and Value are present
                    aiTagsObj[tag.Key] = { value: tag.Value, multiValue: tag.MultiValue }; // Include multiValue flag
                }
            });

            return {
                ...config,
                StaticTags: staticTagsObj,
                Regexs: regularExpressionsObj,
                AiTags: aiTagsObj
            };
        });

        try {
            if (isEdit) {
                agent.Id = id;
                await API.post('/agent/editAgent', agent);
                enqueueSnackbar(t('agents.update.success'), { variant: 'success' })
                goBack();
            }
            else {
                await API.post('/agent/addAgent', agent);
                enqueueSnackbar(t('agents.create.success'), { variant: 'success' });
                goBack();
            }

        }
        catch (error) {
            enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'warning' })
        }
        finally {
            setLoading(false); // Re-enable UI interactions by setting loading to false
        }
    }

    if (loading) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        <>
            <Helmet>
                <title>{t('agentDetail.title', { name: agent ? agent.Name : '' })}</title>
            </Helmet>
            <Container maxWidth='xl'>
                <Box display="flex" flexDirection="column" alignItems="flex-start" mb={2}>
                    <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
                        {isEdit ? t('agentDetail.editTitle', { name: agent.Name }) : t('agentDetail.createTitle')}
                    </Typography>
                    <TextField

                        label={t('agentDetail.agentName')}
                        variant="outlined"
                        value={agent.Name}
                        onChange={handleChange}
                        disabled={!!isEdit} // Disable if editing
                        required
                        sx={{ mb: 2 }}
                    />
                    <Box width="100%">
                        <PathConfigurations
                            pathConfigurations={agent.PathConfigurations}
                            onAdd={handleOpenModalForNew}
                            onEdit={(index) => { handleOpenModalForEdit(index) }}
                            onRemove={(index) => {
                                // Handle removing a specific path configuration by its index
                                const updatedConfigurations = [...agent.PathConfigurations];
                                updatedConfigurations.splice(index, 1);
                                setAgent({ ...agent, PathConfigurations: updatedConfigurations });
                            }}
                        />
                    </Box>
                    <Box display="flex" gap={2}>
                        <Button type='button' variant="outlined" color="secondary" onClick={goBack}>
                            {t('common.back')}
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!isFormValid() || loading} onClick={submitChanges}>
                            {isEdit ? t('agentDetail.saveChanges') : t('agentDetail.createAgent')}
                        </Button>
                    </Box>
                </Box>
            </Container >
            {isModalOpen && <PathConfigurationModal
                open={isModalOpen}
                initialConfig={currentConfig}
                onClose={handleCloseModal}
                onSave={handleSaveConfiguration} />}
        </>
    );
};

export default AgentDetailPage;
