import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles'; // Ensure correct import from @mui/material
import { useTranslation } from 'react-i18next';
import formatFileSize from '../../utils/formaFileSize';
import generateMonthsInRange from '../../utils/generateMonthsInRange';
import { formatNumberWithSpaces } from '../../utils/utility/formatters';

const MonthlyUsageChart = ({ data, fromDate, toDate }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [series, setSeries] = useState([]);
    const [chartOptions, setChartOptions] = useState({
        colors: [theme.palette.error.main, theme.palette.warning.main, theme.palette.primary.main, theme.palette.secondary.main ],
        chart: {
            type: 'line',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: [2, 2],
        },
        xaxis: {
            categories: [] // Will be dynamically updated
        },
        yaxis: [{
            title: {
                text: t('metrics.newFileCount'),
            },
            labels: {
                formatter: function (val) {
                    return formatNumberWithSpaces(val);
                }
            }
        }, {
            opposite: true,
            title: {
                text: t('metrics.newTotalBytes'),
            },
            labels: {
                formatter: function (val) {
                    return formatFileSize(val);
                }
            }
        },
        {
            seriesName: 'File Count (W)',
            show: false
        },
        {
            seriesName: 'Total Bytes (W)',
            show: false,
            opposite: true,

        },
        ],
        tooltip: {
            y: [{
                formatter: function (val) {
                    return `${formatNumberWithSpaces(val)} files`;
                }
            }, {
                formatter: function (val) {
                    return formatFileSize(val);
                }
            },
            {
                formatter: function (val) {
                    return `${formatNumberWithSpaces(val)} files`;
                }
            }, {
                formatter: function (val) {
                    return formatFileSize(val);
                }
            }]
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    });

    const transformData = (fileUsageData) => {
        const months = generateMonthsInRange(fromDate, toDate);
        let series = {
            'W': { fileCounts: [], totalBytes: [] },
            'C': { fileCounts: [], totalBytes: [] }
        };

        months.forEach(month => {
            const monthData = fileUsageData.filter(d => `${d.Year}-${String(d.Month).padStart(2, '0')}` === month);

            let counts = { 'W': 0, 'C': 0 };
            let bytes = { 'W': 0, 'C': 0 };

            monthData.forEach(d => {
                counts[d.StorageClass] += d.UploadFileCount;
                bytes[d.StorageClass] += d.UploadTotalBytes;
            });

            ['W', 'C'].forEach(storageClass => {
                series[storageClass].fileCounts.push(counts[storageClass]);
                series[storageClass].totalBytes.push(bytes[storageClass]);
            });
        });

        return [
            {
                name: 'File Count (W)',
                type: 'line',
                data: series['W'].fileCounts.every(value => value === 0) ? [] : series['W'].fileCounts
            },
            {
                name: 'Total Bytes (W)',
                type: 'line',
                data: series['W'].totalBytes.every(value => value === 0) ? [] : series['W'].totalBytes
            },
            {
                name: 'File Count (C)',
                type: 'line',
                data: series['C'].fileCounts.every(value => value === 0) ? [] : series['C'].fileCounts
            },
            {
                name: 'Total Bytes (C)',
                type: 'line',
                data: series['C'].totalBytes.every(value => value === 0) ? [] : series['C'].totalBytes
            }];
    };


    useEffect(() => {
        setSeries(transformData(data));
        setChartOptions(prevOptions => ({
            ...prevOptions,
            xaxis: { ...prevOptions.xaxis, categories: generateMonthsInRange(fromDate, toDate) }
        }));
    }, [data, fromDate, toDate,]); // Update chart data and options when props change

    return (
        <div id="chart">
            {series.length > 0 && <Chart options={chartOptions} series={series} type="line" height={350} />}
        </div>
    );
};

export default MonthlyUsageChart;
