import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
    const [snackPack, setSnackPack] = useState([]);
    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState(undefined);

    const severityStyleMapping = {
        error: {
            bgcolor: '#AF395F', // Custom red
            color: '#ffffff', // White text
        },
        warning: {
            bgcolor: '#EFC88B', // Custom orange
            color: '#000000',
        },
        info: {
            bgcolor: '#A4BEF3', // Custom blue
            color: '#000000',
        },
        success: {
            bgcolor: '#6e72d8', // Custom purple
            color: '#ffffff',
        },
    };

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
            // Set the next message when there are messages in the queue and no current message is being displayed
            setMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpen(true);
        } else if (snackPack.length && messageInfo && !open) {
            // Directly set the next message if the previous message has already been processed
            setMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpen(true);
        }
    }, [snackPack, messageInfo, open]);

    const enqueueSnackbar = useCallback((message, options = {}) => {
        setSnackPack((prev) => [...prev, { message, options, key: new Date().getTime() }]);
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // const handleExited = () => {
    //     // Triggered when the snackbar finishes its exit transition, allowing us to display the next message
    //     setMessageInfo(undefined);
    // };

    return (
        <SnackbarContext.Provider value={{ enqueueSnackbar }}>
            {children}
            <Snackbar
                key={messageInfo?.key}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleClose}
                    variant="filled"
                    severity={messageInfo?.options.severity || messageInfo?.options.variant || 'info'}
                    sx={{
                        width: '100%',
                        ...(severityStyleMapping[messageInfo?.options.severity || messageInfo?.options.variant || 'info']),
                    }}
                >
                    {messageInfo?.message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};
