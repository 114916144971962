import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, children, loading }) => {

    const { t } = useTranslation();

    const onDialogClose = (event, reason) => {
        if(reason === 'escapeKeyDown' || reason === 'backdropClick'){
            return; // Avoid closing the dialog when clicking outside the or hitting escape key
        }
        return onClose(event, reason);
    }

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirmation-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDialogClose} color="primary" disabled={loading}>
                    {t('common.cancel')}
                </Button>
                <Button onClick={onConfirm} color="primary" disabled={loading} autoFocus>
                    {loading ? <CircularProgress size={24} /> : t('common.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
