import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField, FormControl, CardHeader, Card, CardContent, Box, Grid, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import { useAuth } from '../../../utils/auth/AuthProvider';
import API from '../../../utils/API';
import { HttpStatusCode } from 'axios';
import { useSnackbar } from '../../../utils/SnackbarProvider';
import getErrorMessage from '../../../utils/errorHandler/GetErrorMessage';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../utils/ConfigContext';


export default function LoginForm() {
  const { config, setConfig } = useConfig();

  const navigate = useNavigate();
  const { setToken, setUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [showOtpInput, setShowOtpInput] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [verificationCodeError,] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const setTenancy = (tenancy) => {
    setConfig({ ...config, tenancy: tenancy })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Reset error states
    setEmailError(!email);
    setPasswordError(!password);

    // Early return if errors are found
    if (!email || !password) {
      setLoading(false);
      return;
    }

    const payload = {
      email: email,
      password: password,
      verificationCode: verificationCode
    };

    try {
      const loginResponse = await API.post("/auth/login", payload);
      if (loginResponse.status === HttpStatusCode.Ok) {
        const { data } = loginResponse;

        if (data.OtpUri) {
          navigate('/setup-otp', { state: { otpCodeUri: data.OtpUri, email: email } }); // redirect to OTP setup
          return;
        }

        if (data.AccessToken) {
          setToken(data.AccessToken);
          setUser({ UserName: data.UserName, UserId: data.UserId, UserRole: data.UserRole });
          if (data.UserMustChangePassword) {
            navigate('/change-password');
          }
          else {
            navigate('/dashboard', { replace: true });
          }
          return;
        }
      }

    } catch (err) {
      setLoading(false);

      if (err.response.status === HttpStatusCode.Unauthorized) {
        const { data } = err.response;
        if (data.Code == 'E1002' && !showOtpInput) // invalid opt, ask for a code first time, show error next time
        {
          setShowOtpInput(true);
        }
        else {
          enqueueSnackbar(getErrorMessage(err.response.data, t), { variant: 'warning' })
        }
      }
      else { // Handle other http statuses
        enqueueSnackbar(getErrorMessage(err.response.data, t), { variant: 'warning' })
      }
    }
  };

  return (
    <Paper width={"100%"}>
      <Grid container width={"100%"} mb={3} justifyContent='center'>
        <Grid item md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box textAlign="center" justifyContent="center"
            component="img"
            src="/assets/permastore_logo_vertical.png"
            sx={{ height: 275 }}
          />
        </Grid>
        <Grid item md={6}>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <FormControl variant='standard' sx={{ width: "100%" }}>
              <Card>
                <CardHeader title="Login" />
                <CardContent>
                  <Stack spacing={3}>
                    {!config.tenancy && <TextField
                      required
                      name="tenancy"
                      label={t('login.tenancy')}
                      type="text"
                      value={config.tenancy}
                      onChange={(event) => {
                        setTenancy(event.target.value);
                      }}
                    />}
                    <TextField
                      required
                      name="email"
                      label={t('login.email')}
                      type="email"
                      value={email}
                      error={emailError}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      disabled={showOtpInput}
                    />
                    <TextField
                      required
                      name="password"
                      label={t('login.password')}
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      error={passwordError}
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      disabled={showOtpInput}
                    />
                    {showOtpInput && <TextField
                      name="code"
                      label={t('login.verificationCode')}
                      placeholder="123 456" // Placeholder text
                      type="text"
                      inputProps={{
                        maxLength: 6, // Restrict input length to 6 characters
                        pattern: "\\d{6}", // Ensure it's 6 digits (note: pattern is more for validation)
                      }}
                      value={verificationCode}
                      error={verificationCodeError}
                      helperText={verificationCodeError ? t("login.invalidCode") : t('login.enterCode')} // Replace both instances of helperText with translation keys
                      onChange={(event) => {
                        // Ensure only numbers are allowed and limit to 6 characters
                        const val = event.target.value.slice(0, 6).replace(/[^0-9]/g, '');
                        setVerificationCode(val);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon="ant-design:lock-filled" />
                          </InputAdornment>
                        ),
                      }}
                    />}

                  </Stack>
                </CardContent>
              </Card>
              <LoadingButton
                loading={loading}
                style={{ marginTop: "1rem" }}
                size="large"
                type="submit"
                variant="contained"
              >
                Login
              </LoadingButton>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
}
