import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Stepper, Step, StepLabel, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import PathConfigurationStep from './configurationSteps/PathConfigurationStep';
import TagConfigurationStep from './configurationSteps/TagConfigurationSteps';
import { useSnackbar } from '../../utils/SnackbarProvider';

const PathConfigurationModal = ({ open, onClose, onSave, initialConfig = null }) => {
    const { t } = useTranslation();

    const newConfiguration = {
        Name: '',
        Path: '',
        IncludeSubfolders: false,
        FileFilter: '',
        WaitMinutesBeforeRead: 0,
        ActionAfterSuccesfulStorage: 0,
        MoveDestinationPath: '',
        StaticTagsArray: [],
        AiTagsArray: [],
        RegexsArray: [],
        ReadTagsFromJson: false,
        StorageClass: 'C',
        ApiUrlForTags: '',
        ProvidersConfigurations: []
    };

    const initialState = initialConfig || newConfiguration;

    const [activeStep, setActiveStep] = useState(0);
    const [configuration, setConfiguration] = useState(initialState);
    const [isPathConfigValid, setIsPathConfigValid] = useState(false);
    const [isTagConfigValid, setIsTagConfigValid] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const handleValidityChange = (step, isValid) => {
        if (step === 'pathConfiguration') {
            setIsPathConfigValid(isValid);
        } else if (step === 'tagConfiguration') {
            setIsTagConfigValid(isValid);
        }
    };

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        setConfiguration(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
    };

    const handleTagChange = (type, index, field, value) => {
        if (index === undefined) {
            setConfiguration(prev => ({ ...prev, [type]: value }));
        }
        else {
            const updatedTags = configuration[type].map((tag, idx) => idx === index ? { ...tag, [field]: value } : tag);
            setConfiguration(prev => ({ ...prev, [type]: updatedTags }));
        }

    };

    const handleAddTag = (type) => {
        if (type === 'StaticTagsArray') {
            const canAddTag = configuration[type].length === 0 || configuration[type].some(tag => tag.Key.trim() !== '' && tag.Value.trim() !== '');
            if (!canAddTag) {
                enqueueSnackbar(t('pathConfiguration.completeExistingTagFirst'), { variant: 'warning' });
                return;
            }

        }
        setConfiguration(prev => ({ ...prev, [type]: [...prev[type], { Key: '', Value: '' }] }));
    };

    const handleRemoveTag = (type, index) => {
        setConfiguration(prev => ({ ...prev, [type]: prev[type].filter((_, idx) => idx !== index) }));
    };

    const handleSave = () => {
        onSave(configuration, initialConfig && initialConfig.Name);
        onClose();
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <PathConfigurationStep configuration={configuration} handleChange={handleChange} onValidityChange={(isValid) => handleValidityChange('pathConfiguration', isValid)} />;
            case 1:
                return <TagConfigurationStep configuration={configuration} handleTagChange={handleTagChange} handleAddTag={handleAddTag} handleRemoveTag={handleRemoveTag} onValidityChange={(isValid) => handleValidityChange('tagConfiguration', isValid)} />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{initialConfig ? t('pathConfiguration.editConfiguration') : t('pathConfiguration.addConfiguration')}
                <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step onClick={() => setActiveStep(0)}>
                        <StepLabel>{t('pathConfiguration.pathConfiguration')}</StepLabel>
                    </Step>
                    <Step onClick={() => setActiveStep(1)}>
                        <StepLabel>{t('pathConfiguration.tagConfiguration')}</StepLabel>
                    </Step>
                </Stepper>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                        sx={{ mr: 1 }}
                    >
                        {t('common.back')}
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep === 1 ? (
                        <Button
                            onClick={handleSave}
                            disabled={!isPathConfigValid || !isTagConfigValid}
                        >{t('common.save')}</Button>
                    ) : (
                        <Button
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                            disabled={!isPathConfigValid}>
                            {t('common.next')}
                        </Button>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default PathConfigurationModal;
