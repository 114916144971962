import axios from 'axios';
import { useAuth } from '../auth/AuthProvider';
import { useSnackbar } from '../SnackbarProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SetupAxiosInterceptors = () => {
    const { setToken } = useAuth();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    axios.interceptors.response.use(
        response => response,
        error => {

            if (error.response && error.response.status === 401 && !error.response.data.Code) {
                setToken(null);
                enqueueSnackbar(t('session.expired'), { variant: 'info' });
                navigate("/");
                return;
            }
            return Promise.reject(error);
        }
    );
};

export default SetupAxiosInterceptors;
