import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Router from './routes';
import ScrollToTop from './components/scroll-to-top';
import AuthProvider from './utils/auth/AuthProvider';
import { ThemeProvider } from '@mui/material';
import theme from './utils/theme';
import { SnackbarProvider } from './utils/SnackbarProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { ConfigProvider } from './utils/ConfigContext';


export default function App() {

  return (
    <ConfigProvider>
      <SnackbarProvider>
        <HelmetProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ScrollToTop />
              <AuthProvider>
                <Router />
              </AuthProvider>
            </ThemeProvider>
          </BrowserRouter>
        </HelmetProvider>
      </SnackbarProvider>
    </ConfigProvider>
  );
}
