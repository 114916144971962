import { Grid, TextField, Box, Typography, Paper } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MonthlyUsageChart from "./MonthlyUsageChart";
import HistoricalApiUsage from "./HistoricalApiUsage";
import UsageTrendChart from "./UsageTrendChart";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const HistoricalUsage = ({ monthlyFileUsage, monthlyApiUsage }) => {
    const { t } = useTranslation();
    const [showTrendChart, setShowTrendChart] = useState(false);

    // Calculate the last 12 months date range for default values
    const today = new Date();
    const lastYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

    // Format the dates as YYYY-MM for the input fields
    const formatDate = (date) => {
        let month = '' + (date.getMonth() + 1),
            year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;

        return [year, month].join('-');
    };


    const [filters, setFilters] = useState({
        from: formatDate(lastYear), // Set "from" date to 12 months ago
        to: formatDate(today),
    });

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    // Use a state to control the toggle button's value
    const [chartType, setChartType] = useState('monthly');

    const handleChartTypeChange = (event, newChartType) => {
        if (newChartType !== null) {
            setChartType(newChartType);
            setShowTrendChart(newChartType === 'trend');
        }
    };

    const parseDate = (dateStr) => {
        const [year, month] = dateStr.split('-').map(Number);
        return { year, month };
    };

    const isWithinRange = (itemYear, itemMonth, fromYear, fromMonth, toYear, toMonth) => {
        const fromDate = new Date(fromYear, fromMonth - 1);
        const toDate = new Date(toYear, toMonth - 1);
        const itemDate = new Date(itemYear, itemMonth - 1);
        return itemDate >= fromDate && itemDate <= toDate;
    };

    const { year: fromYear, month: fromMonth } = parseDate(filters.from);
    const { year: toYear, month: toMonth } = parseDate(filters.to);

    const maxDate = formatDate(new Date()); // Current month in YYYY-MM format

    const filteredMonthlyFileUsage = monthlyFileUsage.filter(({ Year, Month }) =>
        isWithinRange(Year, Month, fromYear, fromMonth, toYear, toMonth)
    );

    // const filteredMonthlyApiUsage = monthlyApiUsage.filter(({ Year, Month }) =>
    //     isWithinRange(Year, Month, fromYear, fromMonth, toYear, toMonth)
    // );

    return (
        <Box mt={3}>
            <Box >
                <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>File Storage Usage</Typography>
            </Box>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={2} alignItems="center" wrap>
                    <Grid item xs={12} sm={5} md={3}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="month"
                            name="from"
                            label={t('metrics.from')}
                            value={filters.from}
                            onChange={handleFilterChange}
                            inputProps={{ max: maxDate }} // Prevent future dates
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={3}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="month"
                            name="to"
                            label={t('metrics.to')}
                            value={filters.to}
                            onChange={handleFilterChange}
                            inputProps={{ max: maxDate }} // Prevent future dates
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} >
                        <ToggleButtonGroup
                            color="primary"
                            value={chartType}
                            exclusive
                            onChange={handleChartTypeChange}
                        >
                            <ToggleButton value="monthly">{t('metrics.monthlyChart')}</ToggleButton>
                            <ToggleButton value="trend">{t('metrics.trendChart')}</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container>
                <Grid item xs={12}>
                    {showTrendChart ? (
                        <UsageTrendChart
                            data={filteredMonthlyFileUsage}
                            fromDate={filters.from}
                            toDate={filters.to}
                        />
                    ) : (
                        <MonthlyUsageChart
                            data={filteredMonthlyFileUsage}
                            fromDate={filters.from}
                            toDate={filters.to}
                        />
                    )}
                    {/* <HistoricalApiUsage
                        data={filteredMonthlyApiUsage}
                        fromDate={filters.from}
                        toDate={filters.to}
                    /> */}
                </Grid>
            </Grid>
        </Box>
    );
};

export default HistoricalUsage;

