import React from 'react';
import { TextField, FormControlLabel, Checkbox, Select, MenuItem, FormControl, Box, InputLabel, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';

const PathConfigurationStep = ({ configuration, handleChange, onValidityChange }) => {
    const { t } = useTranslation();

    const renderTooltip = (text) => (
        <Tooltip title={text} placement="top">
            <InfoIcon color="action" style={{ marginLeft: 5, marginBottom: -5 }} />
        </Tooltip>
    );

    const checkValidity = () => {
        const isValid = !!configuration.Name && !!configuration.Path && !!configuration.FileFilter;
        onValidityChange(isValid);
    };

    const updatedHandleChange = (event) => {
        handleChange(event);
        checkValidity();
    };

    checkValidity();

    return (
        <Box>
            <Box display="flex" alignItems="center">
                <TextField
                    name="Name"
                    label={t('pathConfiguration.name')}
                    value={configuration.Name}
                    onChange={updatedHandleChange}
                    fullWidth
                    margin="normal"
                    required
                />
                {renderTooltip(t('tooltips.pathConfiguration.name'))}
            </Box>
            <Box display="flex" alignItems="center">
                <TextField
                    name="Path"
                    label={t('pathConfiguration.path')}
                    value={configuration.Path}
                    onChange={updatedHandleChange}
                    fullWidth
                    margin="normal"
                    required
                />
                {renderTooltip(t('tooltips.pathConfiguration.path'))}
            </Box>
            <FormControlLabel
                control={<Checkbox
                    checked={configuration.IncludeSubfolders}
                    onChange={updatedHandleChange}
                    name="IncludeSubfolders" />}
                label={<Box display="flex" alignItems="center">{t('pathConfiguration.includeSubdirectories')}</Box>}
            />
            {renderTooltip(t('tooltips.pathConfiguration.includeSubdirectories'))}
            <Box display="flex" alignItems="center">
                <TextField
                    name="FileFilter"
                    label={t('pathConfiguration.fileFilter')}
                    value={configuration.FileFilter}
                    onChange={updatedHandleChange}
                    fullWidth
                    margin="normal"
                    required
                />
                {renderTooltip(t('tooltips.pathConfiguration.fileFilter'))}
            </Box>
            <Box display="flex" alignItems="center">
                <TextField
                    name="WaitMinutesBeforeRead"
                    label={t('pathConfiguration.waitMinutesBeforeRead')}
                    value={configuration.WaitMinutesBeforeRead}
                    onChange={updatedHandleChange}
                    fullWidth
                    margin="normal"
                    required
                    type="number"
                    inputProps={{
                        min: 0,
                        step: 1
                    }}
                />
                {renderTooltip(t('tooltips.pathConfiguration.waitMinutesBeforeRead'))}
            </Box>
            <Box display="flex" alignItems="center">
                <FormControl fullWidth margin="normal">
                    <InputLabel>{t('pathConfiguration.storageClass')}</InputLabel>
                    <Select
                        label={t('pathConfiguration.storageClass')}
                        id="storageClass"
                        name="StorageClass"
                        value={configuration.StorageClass}
                        onChange={updatedHandleChange}
                        required
                        variant='outlined'
                    >
                        <MenuItem value="C">{t('pathConfiguration.storageClasses.Cold')}</MenuItem>
                        <MenuItem value="W">{t('pathConfiguration.storageClasses.Warm')}</MenuItem>
                    </Select>
                </FormControl>
                {renderTooltip(t('tooltips.pathConfiguration.storageClass'))}
            </Box>
            <Box display="flex" alignItems="center">
                <FormControl fullWidth margin="normal">
                    <InputLabel>{t('pathConfiguration.actionAfterStorage')}</InputLabel>
                    <Select
                        label={t('pathConfiguration.actionAfterStorage')}
                        id="actionAfterStorage"
                        name="ActionAfterSuccesfulStorage"
                        value={configuration.ActionAfterSuccesfulStorage}
                        onChange={updatedHandleChange}
                        required
                        variant='outlined'
                    >
                        <MenuItem value={0}>{t('pathConfiguration.delete')}</MenuItem>
                        <MenuItem value={1}>{t('pathConfiguration.move')}</MenuItem>
                    </Select>
                </FormControl>
                {renderTooltip(t('tooltips.pathConfiguration.actionAfterStorage'))}
            </Box>
            {configuration.ActionAfterSuccesfulStorage === 1 && (
                <Box display="flex" alignItems="center">
                    <TextField
                        name="MoveDestinationPath"
                        label={t('pathConfiguration.moveDestinationPath')}
                        value={configuration.MoveDestinationPath}
                        onChange={updatedHandleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    {renderTooltip(t('tooltips.pathConfiguration.moveDestinationPath'))}
                </Box>
            )}
        </Box>
    );
};

export default PathConfigurationStep;
