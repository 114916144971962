import React from 'react';
import { Box, Container, Typography, Paper, Divider, Grid, Skeleton } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import CurrentStats from "../sections/metrics/CurrentStats";
import HistoricalUsage from "../sections/metrics/HistoricalUsage";
import { useState } from 'react';
import { useEffect } from 'react';
import API from '../utils/API';
import getErrorMessage from '../utils/errorHandler/GetErrorMessage';
import { useSnackbar } from '../utils/SnackbarProvider';
import { HttpStatusCode } from 'axios';

export default function MetricsPage() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [monthlyFileUsage, setMonthlyFileUsage] = useState({ loading: true, data: [] });
    const [monthlyApiUsage, setMonthlyApiUsage] = useState({ loading: true, data: [] });


    useEffect(() => {
        const fetchFileUsage = async () => {
            setMonthlyFileUsage(prevState => ({ loading: true, ...prevState }));
            try {
                const response = await API.post('/usage/monthlyFileUsage');
                if (response.status === HttpStatusCode.Ok) {
                    setMonthlyFileUsage({
                        data: response.data,
                        loading: false
                    });
                }
            } catch (error) {
                setMonthlyFileUsage({ data: [] });
                enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'warning' })
            }
            finally {
                setMonthlyFileUsage(prevState => ({ loading: false, ...prevState }));
            }
        };

        const fetchApiUsage = async () => {
            setMonthlyApiUsage(prevState => ({ loading: true, ...prevState }));
            try {
                const response = await API.post('/usage/monthlyApiUsage');
                if (response.status === HttpStatusCode.Ok) {
                    setMonthlyApiUsage({
                        data: response.data,
                        loading: false
                    });
                }
            } catch (error) {
                setMonthlyApiUsage({ data: [] });
                enqueueSnackbar(getErrorMessage(error.response.data, t), { variant: 'warning' })
            }
            finally {
                setMonthlyApiUsage(prevState => ({ loading: false, ...prevState }));
            }
        };

        fetchApiUsage();
        fetchFileUsage();
    }, [])

    const getTotaleUsage = (monthUsage) => {
        return monthUsage.reduce((acc, curr) => {
            acc.UploadFileCount += curr.UploadFileCount;
            acc.UploadTotalBytes += curr.UploadTotalBytes;
            acc.DownloadFileCount += curr.DownloadFileCount;
            acc.DownloadTotalBytes += curr.DownloadTotalBytes;
            acc.RemovedFileCount += curr.RemovedFileCount;
            acc.RemovedTotalBytes += curr.RemovedTotalBytes;
            return acc;
        }, {
            UploadFileCount: 0,
            UploadTotalBytes: 0,
            DownloadFileCount: 0,
            DownloadTotalBytes: 0,
            RemovedFileCount: 0,
            RemovedTotalBytes: 0
        });
    }

    const totalFileUsage = getTotaleUsage(monthlyFileUsage.data);

    return (
        <>
            <Helmet>
                <title>{t('metrics.title')}</title>
            </Helmet>
            <Container maxWidth='xl'>
                <Box mb={4}>
                    <Typography variant="h4" gutterBottom>
                        {t('metrics.currentUsage')}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: 'text.secondary' }}>
                        {t('metrics.currentUsageSubtitle')}
                    </Typography>
                    <Paper elevation={3} sx={{ p: 2, mt: 2, backgroundColor: '#1f557233' }}>
                        {monthlyFileUsage.loading ? (
                            <Grid container spacing={1}>
                                {Array.from(new Array(4)).map((_, index) => (
                                    <Grid item xs={12} sm={6} md={3} key={index}>
                                        <Skeleton variant="rectangular" width={200} height={118} sx={{ borderRadius: 2 }} />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <CurrentStats 
                            totalUploadFileCount={totalFileUsage.UploadFileCount - totalFileUsage.RemovedFileCount} 
                            totalUploadBytesCount={totalFileUsage.UploadTotalBytes - totalFileUsage.RemovedTotalBytes} 
                            totalDownloadFileCount={totalFileUsage.DownloadFileCount} 
                            totalDownloadBytesCount={totalFileUsage.DownloadTotalBytes} 
                            />
                        )}
                    </Paper>
                </Box>
                <Divider sx={{ my: 4 }} />
                <Box mb={4}>
                    <Typography variant="h4" gutterBottom>
                        {t('metrics.analytics')}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: 'text.secondary' }}>
                        {t('metrics.analyticsSubtitle')}
                    </Typography>
                    <Paper elevation={3} sx={{ p: 2, mt: 2, bgcolor: "#e0efff5e" }}>
                        <HistoricalUsage monthlyFileUsage={monthlyFileUsage.data} monthlyApiUsage={monthlyApiUsage.data}  />
                    </Paper>
                </Box>
            </Container>
        </>
    );
}
