import {
    Navigate,
    Outlet,
} from 'react-router-dom';
import { useAuth } from './AuthProvider';

const ProtectedRoute = ({ redirectPath = '/login', children }) => {

    const { token } = useAuth();

    if (!token) {
        return <Navigate to={redirectPath} replace />;
    }
    return children ? children : <Outlet />;
};

export default ProtectedRoute;
