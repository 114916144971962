import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import FolderIcon from '@mui/icons-material/Folder';
import CableIcon from '@mui/icons-material/Cable';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const navConfig = [
  {
    title: 'files.title',
    path: '/dashboard/files',
    icon: <FolderIcon color='primary' />,
    onlyAdmin: false,
  },
  {
    title: 'users.title',
    path: '/dashboard/users',
    icon: <PersonIcon htmlColor='#04bbcb' />,
    onlyAdmin: true,
  },
  {
    title: 'group.title',
    path: '/dashboard/groups',
    icon: <GroupsIcon htmlColor='#af395f' />,
    onlyAdmin: true,
  },
  {
    title: 'agents.title',
    path: '/dashboard/agents',
    icon: <CableIcon htmlColor='#1F5673' />,
    onlyAdmin: true,
  },
  {
    title: 'metrics.title',
    path: '/dashboard/metrics',
    icon: <QueryStatsIcon htmlColor='#E2C044' />,
    onlyAdmin: true,
  }

];

export default navConfig;
