import React from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Grid, TableContainer, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { tableStyle } from '../../utils/cssStyles';
import { useTranslation } from 'react-i18next';

const PathConfigurations = ({ pathConfigurations, onAdd, onEdit, onRemove }) => {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="space-between" mb={5} width="100%">
            <Grid item xs={12}>
                <TableContainer component={Paper} sx={tableStyle.tableContainer}>
                    <Table sx={tableStyle.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('pathConfiguration.name')}</TableCell>
                                <TableCell>{t('pathConfiguration.path')}</TableCell>
                                <TableCell align="right">{t('common.actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pathConfigurations.map((config, index) => (
                                <TableRow key={index} style={tableStyle.tableRow}>
                                    <TableCell sx={tableStyle.bodyCell} component="th" scope="row">{config.Name}</TableCell>
                                    <TableCell sx={tableStyle.bodyCell} component="th" scope="row">{config.Path}</TableCell>
                                    <TableCell sx={tableStyle.bodyCell} align="right">
                                        <IconButton onClick={() => onEdit(index)}><EditIcon /></IconButton>
                                        <IconButton onClick={() => onRemove(index)}><DeleteIcon /></IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={onAdd} variant="contained" color='secondary' style={{ marginTop: '10px' }}>Add Path Configuration</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PathConfigurations;
